import React from 'react';
import RectSvg from '../../ShapeSvgs/Rect';
import CustomSvg from '../../ShapeSvgs/Custom';

class Margin extends React.Component {
    render() {
        console.log("this.props.selectedShape.shape_title :: ",this.props.selectedShape.shape_title);
        if(this.props.selectedShape.shape_title === 'Rect'){
            return (
                <RectSvg x={0} y={0} id="cutmargin" stroke="#ff0000" strokeWidth={1} fill="none" height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit} rx={this.props.actualradious} ry={this.props.actualradious}></RectSvg>    
            )
        }else if(this.props.selectedShape.shape_title === 'Square'){
            return (
                <RectSvg x={0} y={0} id="cutmargin" stroke="#ff0000" strokeWidth={1} fill="none" height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit} rx={this.props.actualradious} ry={this.props.actualradious}></RectSvg>    
            )
        }else if(this.props.selectedShape.shape_title === 'Circle'){
            return (
                <CustomSvg id="cutmargin" strokeLinejoin="round" d="M652.006,518.029c24.284,0.001,43.97,19.688,43.97,43.972s-19.687,43.97-43.972,43.97  c-24.283,0-43.97-19.688-43.969-43.972c0-24.283,19.686-43.969,43.969-43.97H652.006z" stroke="#ff0000" strokeWidth={1} fill="none" height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>
            )
        }else if(this.props.selectedShape.shape_title === 'Oval'){
            return (
                <CustomSvg id="cutmargin" strokeLinejoin="round" d="M392.006,258.5c48.325,0,87.5,19.477,87.499,43.501S440.329,345.5,392.004,345.5s-87.5-19.476-87.499-43.5  c0-24.024,39.175-43.499,87.499-43.5H392.006z" stroke="#ff0000" strokeWidth={1} fill="none" height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>
            )
        }else if(this.props.selectedShape.shape_title === 'Diamond'){
            return (
                <CustomSvg id="cutmargin" strokeLinejoin="round" d="M696,410.5l87.579,43.77L696,498.04l-87.58-43.771L696,410.5z" stroke="#ff0000" strokeWidth={1} fill="none" height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>    
            )
        }else if(this.props.selectedShape.shape_title === 'Custom'){
            let d = (this.props.sizeData.customD) ? this.props.sizeData.customD : "M344.67,279.01c64.298,8.722,90.041-34.134,120.24-11.562c19.646,16.802-29.829,12.818,10.961,58.855	c36.631,41.34-54.376-8.105-114.566,16.071c-34.214,13.744-79.149,9.644-39.501-22.769	C346.096,299.751,280.371,270.287,344.67,279.01z" 
            return (
                <CustomSvg id="cutmargin" strokeLinejoin="round" d={d} stroke="#ff0000" strokeWidth={1} fill="none" height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>    
            )
        }    
    }
}
export default Margin

// return (
//     <svg height={this.props.height} width={this.props.width} distancevalue={this.props.margin}> 
//         <Heart id="cutmargin" strokeLinejoin="round" d="M70.33952,35.80746C99.34181,-52.10137 212.96642,35.80746 70.33952,148.8372C-72.28889,35.80746 41.33672,-52.10086 70.33952,35.80746 z" stroke="#ff0000" strokeWidth={1} fill="none" height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></Heart>
//     </svg>
// )
