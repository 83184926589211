import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import '../Extras/ExtrasItem.css';

let dropDownDataArr = [];
class ExtrasItem extends React.Component {
    constructor(props){
      super(props);

      this.state = {
        selectedDropdownValue : [], 
      }
    }

    componentWillMount = () => {
      //console.log("will mount extraData :: ",this.props.extraData);
      if(this.props.extraData !== null){      
        this.setState({selectedDropdownValue : this.props.extraData});
      }else{
        this.setState({selectedDropdownValue : []});
        dropDownDataArr = [];
      }
    }

    renderDiscriptionVal = (discriptionVal)=>{
      if(discriptionVal){
        discriptionVal = discriptionVal.split(",");
      }
      return(
        discriptionVal.map((obj,index) => (
            <li key={index}>{obj}</li>
        ))    
      ) 
    }

    renderButton = (dropDownName,dropDownData,dropDownIndex) => {
      //console.log("dropDownName :: ",dropDownName);
      /* return(
        dropDownData.map((obj,index) => (
          <div className="col-md-12" key={index} onClick={this.onButtonClick}>
            <div className={
              
              (this.state.selectedDropdownValue.length > 0 && this.state.selectedDropdownValue[this.state.selectedDropdownValue[dropDownIndex].index]) ?

              (obj.title === this.state.selectedDropdownValue[this.state.selectedDropdownValue[dropDownIndex].index].value) ? "material-btn mb-2 active" : "material-btn mb-2"

              : "material-btn mb-2"
              
            } value={obj.title} price={obj.price} index={dropDownIndex}>
              <span className="btn btn-secondary w-100">{obj.title}</span>
            </div>
          </div>
        ))  
      )  */
      /* ********** Logic change because complete data was not availabel ********** */
      return(
        dropDownData.map((obj,index) => (
          <div className="col-md-12" key={index} onClick={this.onButtonClick}>
            <div className={

              (this.state.selectedDropdownValue.length > 0) ?

              (this.state.selectedDropdownValue.find(selectedObj => {return(selectedObj.value === obj.title)})) ? "material-btn mb-2 active" : "material-btn mb-2"

              : "material-btn mb-2"
              
            } value={obj.title} price={obj.price} dropdownname={dropDownName} index={dropDownIndex}>
              <span className="btn btn-secondary w-100">{obj.title}</span>
            </div>
          </div>
        ))  
      ) 
      /* **************************************************************************** */

    }
    
    onButtonClick = (e) => {
      dropDownDataArr.map((object,i) => {
          if(object.index === e.target.getAttribute('index')){
            dropDownDataArr.splice(i,1);
          }
          return object;
      })
      dropDownDataArr.push({"index" : e.target.getAttribute('index'),"value" : e.target.getAttribute("value"), "price" : e.target.getAttribute("price"), "title" : e.target.getAttribute("dropdownname")});
      this.setState({selectedDropdownValue : dropDownDataArr});


      /*this.state.selectedDropdownValue.map((object,i) => {
        if(object.index === e.target.getAttribute('index')){
          this.state.selectedDropdownValue.splice(i,1);
        }
        return object;
      })*/

      /*this.setState({
        selectedDropdownValue: this.state.selectedDropdownValue.push({"index" : e.target.getAttribute('index'),"value" : e.target.getAttribute("value"), "price" : e.target.getAttribute("price"), "title" : e.target.getAttribute("dropdownname")})
      });*/

      
      this.props.selectedExtraData(dropDownDataArr);
    }

    render() {
      //console.log("this.state.selectedDropdownValue :: ",this.state.selectedDropdownValue)
      //this.props.selectedExtraData(dropDownDataArr);

      let price = "$0.00";
      /*if(this.state.selectedDropdownValue.length > 0){
        if(this.state.selectedDropdownValue[this.props.index]){
          price = this.state.selectedDropdownValue[this.state.selectedDropdownValue[this.props.index].index].price;
        }
      }*/
      if(this.state.selectedDropdownValue.length > 0){
        let selectedObj
        this.state.selectedDropdownValue.map((obj) => {
          if(parseInt(obj.index) === this.props.index){
            selectedObj = obj;
          }
          return obj;
        })
        if(selectedObj){
          price = selectedObj.price;
        }
      }

      //console.log("this.props.extraOptionData.title :: ",this.props.extraOptionData.title);

      return(
        <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
          <div className="media material-box bg-white mb-4 d-flex d-lg-flex d-md-block flex-wrap flex-sm-nowrap flex-md-wrap flex-lg-nowrap w-100">
            <div className="material-box-img col-12 col-sm-6 col-md-12 col-lg-5 px-0 pl-sm-3 px-md-0 pl-lg-3 pr-lg-0 order-sm-2 order-md-1 order-lg-2 mb-4 mb-sm-0 mb-md-4 mb-lg-0 text-center">
              <img src={this.props.extraOptionData.media} width="100%" alt="img"></img>
            </div>
            <div className="media-body order-sm-1 order-md-2 order-lg-1">
              <h5 className="mt-0 mb-3">{this.props.extraOptionData.title}</h5>
              <div className="media-body-content">
                <ul>
                {this.renderDiscriptionVal(this.props.extraOptionData.discription)}
                </ul>
                
              </div>
              <div className="price-block onsheet-price w-100 d-flex justify-content-between mb-2 mt-2">
                  <div className="material-price mb-2 font-weight-bold float-left align-self-end">+{price}</div>
                  <div className="material-price mb-2 font-weight-bold float-right align-self-end"><span className="d-block total-price-extras">Total price</span>$15.00</div>
              </div>
              
              <div className="row">
                {this.renderButton(this.props.extraOptionData.title,this.props.extraOptionData.values,this.props.index)}
              </div>  
            </div>
          </div>
        </div>
      ) 
    }
  }
export default ExtrasItem;



