import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import MaterialItem from '../Material/MaterialItem';

class MaterialItems extends React.Component {
    render() {
        return (
            <div className="col-lg-6 col-md-6 col-sm-12 d-flex">
                <MaterialItem onClick={this.props.onClick} materialMasterData={this.props.materialMasterData[this.props.materialData.material]} materialValues={this.props.materialData.values} sheetData={this.props.sheetData} key={this.props.materialData.id} materialName={this.props.materialData.material} selectedMaterial={this.props.selectedMaterial}></MaterialItem>
            </div> 
        )
    }
}
export default MaterialItems;