import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "../Summary/Summary.css";


class FiveStep extends React.Component {
    
    state = {
      previewImg: ""
    }

    componentWillMount(){
      //console.log("summery will mount fired***");
      //console.log(this.props.getJsonData);

      /*if(this.props.customizeData.canvas !== null){
        console.log(this.props.customizeData.canvas.toSVG());
        console.log(this.props.customizeData.canvas);
        let canvas = this.props.customizeData.canvas;
        let img = canvas.toDataURL('image/png');
        console.log("img :: ",img);
      }*/

      if(this.props.customizeData.canvas !== null){
        this.props.customizeData.canvas.overlayImage = null;
        this.props.customizeData.canvas.renderAll();
        const previewImg = this.props.customizeData.canvas.toDataURL('image/png');
        this.setState({previewImg : previewImg});
      }
    }

    setShapeValue = (value) => {
      return(
        this.props.getJsonData != null ?
        (
          value.map((shapeDataItems) => (
              shapeDataItems.map((shapeDataItem, index) => (
                <option value={shapeDataItem.shape_title} key={index}>{shapeDataItem.shape_title}</option>
              ))
          ))
        ):null
      )
    }

    setMaterialsValue = (value) => {

      //console.log("values :: ",value);

      return(
        value.map((optionsData) => (
          optionsData.values.map((materialType,index) => (
          <option key={index} value = {this.props.getJsonData.matrial_master[optionsData.material].title + " - " + this.props.getJsonData.sheet_rool_master[materialType.sheet].title}>{this.props.getJsonData.matrial_master[optionsData.material].title + " - " + this.props.getJsonData.sheet_rool_master[materialType.sheet].title}</option>
          ))
        ))
      )
    }

    onShapeChange = () => {

    }
    
    onExtraChange = () => {

    }
    onMaterialChange = () => {

    }
    onUnitChange = () => {

    }

    setUnitValue = (value) => {
      return(
        <option value={value.unit}>{value.unit}</option>
      )
    }


    /*setExtraValues = () => {
      
      if(this.props.extraData === null) return;

      let materialName = this.props.materialData.selectedMaterial.getAttribute("materialname");
      let sheetName = this.props.materialData.selectedMaterial.getAttribute("value");
      
      let extraOptionData = null;
      this.props.getJsonData.material.map((optionsData) => {
        if(optionsData.material === materialName){
          optionsData.values.map(materialValue => {
            if(materialValue.sheet === sheetName){
              extraOptionData = materialValue.extra_option;
            }
          })
        }
      })

      //console.log("extraOptionData :: ",extraOptionData);

      console.log("this.props.extraData :: ",this.props.extraData);
      
      return(
        extraOptionData.map((extraOptions,index) => {
          console.log("this.props.extraData :: ",this.props.extraData);
          let selectedIndex = this.props.extraData[index].index;

          console.log("selectedIndex :: ",selectedIndex);
          return (
            <div className="col-md-12 mb-3" key={index}>
              <div className="row align-items-center">
                <label key={index} className="col-sm-3 col-form-label line-height-1">{extraOptions.title}</label>
                <div className="col-sm-9">
                  <select className="form-control" value={this.props.extraData[selectedIndex].value} onChange={this.onExtraChange}>
                  {
                    
                    extraOptions.values.map((extraOptionValue,index) => {
                      return(<option key={index} value={extraOptionValue.title}>{extraOptionValue.title}</option>)
                    })
                  }
                  </select>
                </div>
              </div>
            </div>
          )
        })
        
      )

    }*/

    setExtraValue = () => {
      //console.log("this.props.extraData :: ",this.props.extraData);
      if(this.props.extraData === null) return;

      let materialName = this.props.materialData.selectedMaterial.getAttribute("materialname");
      let sheetName = this.props.materialData.selectedMaterial.getAttribute("value");
      
      let extraOptionData;
      this.props.getJsonData.material.map((optionsData) => {
        if(optionsData.material === materialName){
          optionsData.values.map(materialValue => {
            if(materialValue.sheet === sheetName){
              extraOptionData = materialValue.extra_option;
            }
          })
        }
      })
      //console.log("extraOptionData ",extraOptionData);
      if(extraOptionData === undefined) return
      return(
        this.props.extraData.map((extraData,index)=>{
          return (
            <div className="col-md-12 mb-3" key={index}>
              <div className="row align-items-center">
                <label key={index} className="col-sm-3 col-form-label line-height-1">{extraData.title}</label>
                <div className="col-sm-9">
                  <select className="form-control" value={extraData.value} onChange={this.onExtraChange}>
                  {
                    extraOptionData.map((extraOptionData,index) => {
                      return(
                        extraOptionData.values.map((optionValue,index)=>{
                          return(<option key={index} value={optionValue.title}>{optionValue.title}</option>)
                        })
                      )
                    })
                  }
                  </select>
                </div>
              </div>
            </div>
          )
        })
      )
    }

    onFinishClick = (isFinish)=>{
      
      
    }
    
    render() {
      //console.log("this.props.sizeData :: ",this.props.sizeData);
      //console.log(this.props.materialData.selectedMaterial);
      let selectedMaterial = this.props.materialData.selectedMaterial.getAttribute("materialname");
      let selectedSheet = this.props.materialData.selectedMaterial.getAttribute("value");
      let selctedMaterialTitle = this.props.getJsonData.matrial_master[selectedMaterial].title +" - "+ this.props.getJsonData.sheet_rool_master[selectedSheet].title;

      //console.log("selctedMaterialTitle :: "+selctedMaterialTitle);
      this.onFinishClick(this.props.isFinish);

      return(
        <div className="tabs-content-wrapper">
          <div className="tab-title w-100 text-center mb-4">
              <h2 className="position-relative text-center d-inline-block text-uppercase m-0">summary</h2>
          </div>
          <div className="tabs-inner-content summary-wrapper text-left">
            <div className="row">
              <div className="col-md-12 col-lg-5 pr-xl-5 pr-lg-4">
                <form>
                <fieldset disabled>
                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Shape</label>
                    <div className="col-sm-9">
                    <select id="shapevalue" className="form-control" value = {this.props.selectedShape.shape_title} onChange={this.onShapeChange}>
                      {this.setShapeValue(this.props.getJsonData.tooldata.optionvalues)}
                    </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Size</label>
                    <div className="col-sm-9">
                      <div className="row align-items-center">
                        <div className="col-8">
                          <div className="input-group mb-2">
                            <input type="text" className="form-control" id="sizewidth" defaultValue={this.props.sizeData.widthval}/>
                            <div className="input-group-prepend">
                              <div className="input-group-text">w</div>
                            </div>
                          </div>
                          <div className="input-group mb-2">
                          <input type="text" className="form-control col" id="sizeheight" defaultValue={this.props.sizeData.heightval}/>
                            <div className="input-group-prepend">
                              <div className="input-group-text">h</div>
                            </div>
                          </div>

                        </div>
                        <div className="col-4 pl-0">
                          <select id="sizevaluetype" className="form-control" onChange={this.onUnitChange}>
                            {this.setUnitValue(this.props.getJsonData.config)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                  {this.props.sizeData.radiousval && this.props.sizeData.radiousval !== "" &&
                    <div className="form-group row align-items-center">
                      <label className="col-sm-3 col-form-label line-height-1">Corner radius</label>
                      <div className="col-sm-9">
                        <div className="row">
                          <div className="col-8">
                              <input type="text" className="form-control col" id="cornerradius" defaultValue={this.props.sizeData.radiousval}/>
                          </div>
                          <div className="col-4 pl-0">
                            <select id="cornerradiusvalue" className="form-control" onChange={this.onUnitChange}>
                              {this.setUnitValue(this.props.getJsonData.config)}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  }

                  {/* <div className="form-group row align-items-center">
                    <label className="col-sm-3 col-form-label line-height-1">Corner radius</label>
                    <div className="col-sm-9">
                      <div className="row">
                        <div className="col-md-6">
                            <input type="text" className="form-control col" id="cornerradius" defaultValue={this.props.sizeData.radiousval}/>
                        </div>
                        <div className="col-md-6">
                          <select id="cornerradiusvalue" className="form-control" onChange={this.onUnitChange}>
                            {this.setUnitValue(this.props.getJsonData.config)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Quantity</label>
                    <div className="col-sm-9">
                      
                        <input type="text" className="form-control col" id="quantity" defaultValue={this.props.sizeData.quantity}/>
                      
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-sm-3 col-form-label">Material</label>
                    <div className="col-sm-9">
                    <select id="materialvalue" className="form-control" value={selctedMaterialTitle} onChange={this.onMaterialChange}>
                      {this.setMaterialsValue(this.props.getJsonData.material)}
                    </select>
                    </div>
                  </div>

                  <div className="form-group row">
                    {(this.props.extraData !== null && this.props.extraData.length > 0) ? this.setExtraValue(this.props.extraData) : ""}
                  </div>
                  </fieldset>
                </form>
               
              </div>
              <div className="col-md-12 col-lg-7 pl-lg-5">
                <h3 className="total-price-label">Total price for this design</h3>
                <h2 className="font-weight-bold total-price">$45.00</h2>
                <small className="mb-3">All prices shown are excluding VAT &amp; delivery unless otherwise stated. </small>
                <hr/>
                {/* <button className="btn btn-primary btn-lg btn-block">Design online / upload artwork</button> */}
                <div className="design-preview-box position-relative">
                  <div className="tab-title w-100 text-left mb-2">
                    <h2 className="position-relative text-left d-inline-block text-uppercase my-0 ml-4 ml-sm-5 mr-0 px-2">Design Preview</h2>
                  </div>
                  <div className="design-preview-img text-center pt-1 pb-3 px-3">
                    <img className="img-responsive" src={this.state.previewImg} alt="img"/>
                  </div>
                </div>
              </div>
            </div>

           
          </div>
        </div>
      ) 
    }
  }
export default FiveStep;



