import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import ExtraItem from './ExtrasItem';

class ExtraItems extends React.Component {
    render() {
      if(this.props.extraOptionData === undefined){
        return <div className="col-md-12"><div className="alert alert-warning" role="alert">No Extra Data Found.</div></div>
      }

      return(
        
        <div className="col-md-12">
          <div className="row">
            {
              this.props.extraOptionData.map((extraOptionData,index) => (
                  <ExtraItem extraOptionData={extraOptionData} key={index} index={index} selectedExtraData={this.props.selectedExtraData} extraData={this.props.extraData}></ExtraItem>
              ))    
            }
          </div>
        </div>
      ) 
    }
  }
export default ExtraItems;  



