import React, { Fragment } from 'react';
import { PhotoshopPicker } from 'react-color';
import PropTypes from 'prop-types';
import Text from '../DesignTool/Text';
import Image from '../DesignTool/Image';
import Rect from '../DesignTool/Rect';
import "../DesignTool/Designcanvas.css";
import ActiveObject from "./ActiveObject";
//import RectSvg from '../ShapeSvgs/Rect';
//import CanvasClipPath from './CanvasClipPath/CanvasClipPath';
import Margins from './CanvasClipPath/Margins';
import PropertyPanel from "./PropertyPanel/PropertyPanel";
//import RectSvg from '../ShapeSvgs/Rect';

const fabric = window.fabric
let canvas;
let avail_width,avail_height;
class DesignCanvas extends React.Component {
  static propTypes = {
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
  }

  static defaultProps = {
    width: 600,
    height: 400,
  }

  state = {
    canvas: null,
    objectArr : [],
    selectedObject : null,
    //avail_width : 555,
    //avail_height : 312
    //toDataURL : ""
  }

  componentDidMount() {
    this.setCanvasAsperScreen();
    this.fileSelector = this.buildFileSelector();
    this.onAddImageChange = this.onAddImageChange.bind(this);
    //this.onAddImageClick = this.onAddImageClick.bind(this);
    this.updateCanvas();
  }

  updateCanvas = ()=>{
    let actualwidth;
    let actualheight;
    let actualradious;
    //let avail_width = 555;//635;
    //let avail_height = 312;
    let marginvalue = this.props.getJsonData.config.margin * 2 * 3.78;
    let fitwidth;
    let fitheight;
    
    if (this.props.getJsonData.config.unit === "mm") {
      actualwidth = (this.props.sizeData.widthval * 3.78) + marginvalue;
      actualheight = (this.props.sizeData.heightval * 3.78) + marginvalue;
      actualradious = this.props.sizeData.radiousval * 3.78;
    } else {
      actualwidth = this.props.sizeData.widthval;
      actualheight = this.props.sizeData.heightval;
    }
    let size_ratio = actualwidth / actualheight;
    if (actualwidth > actualheight) {
      fitwidth = avail_width;
      fitheight = avail_width / size_ratio;
      if (fitheight > avail_height) {
        fitheight = avail_height;
        fitwidth = avail_height * size_ratio;
      }
    } else {
      fitheight = avail_height;
      fitwidth = avail_height * size_ratio;
      if (fitwidth > avail_width) {
        fitwidth = avail_width;
        fitheight = avail_width / size_ratio;
      }
    }
    canvas = new fabric.Canvas(this.c, {
      width: fitwidth,
      height: fitheight,
    });
    canvas.preserveObjectStacking = true;
    canvas.selection = false;
    
    
    let ratio_to_fit = fitwidth / actualwidth;
    //scale canvas size//
    
    canvas.on('mouse:up',this.setActiveObject);
    //canvas.on('object:modified',this.setActiveObject);
    //canvas.on('selection:updated',this.setActiveObject);
    canvas.on('object:added',this.updateActiveObject);
    canvas.on('object:removed',this.setActiveObject);
    canvas.on('mouse:over',this.onCanvasOver);
    canvas.on('mouse:out',this.onCanvasOut);
    //canvas.on('mouse:over',this.setActiveObject);
    
    this.setState({ canvas });
    //console.log("selectedShape :: ",this.props.selectedShape);
    this.setState({
       objectArr: [...this.state.objectArr,<Margins sizeData={this.props.sizeData} selectedShape={this.props.selectedShape} ratio_to_fit={ratio_to_fit} margin={marginvalue} height={fitheight} width = {fitwidth} actualradious={actualradious}/>] 
    });
  }

  onCanvasOver = (e)=>{
    (e.target && e.target.id === "Layer_1") ? canvas.hoverCursor = 'default' : canvas.hoverCursor = 'move';
  }

  onCanvasOut = (e)=>{
    (e.target && e.target.id === "Layer_1") ? canvas.hoverCursor = 'default' : canvas.hoverCursor = 'move';
  }

  setCanvasAsperScreen = ()=>{
    console.log("window.screen.width :: ",window.screen.width);
    if(window.screen.width >= 1199){
      avail_width = 515;
      avail_height = 254;
    }else if(window.screen.width <= 1198 && window.screen.width >= 768){
      avail_width = 425;
      avail_height = 210;
    }else if(window.screen.width <= 767 && window.screen.width >= 575){
      avail_width = 495;
      avail_height = 244;
    }else if(window.screen.width <= 574 && window.screen.width >= 480){
      avail_width = 400;
      avail_height = 197;
    }else {
      avail_width = 261;
      avail_height = 129;
    }
  }


  onNextClick = () => {
    //this.setState({toDataURL:this.state.canvas.toDataURL('image/png')});
    return this.state;
  }

  onPreviousClick = () => {
    //this.setState({toDataURL:this.state.canvas.toDataURL('image/png')});
    return this.state;
  }

  setActiveObject = () =>{
    this.setState({selectedObject : this.state.canvas.getActiveObject()});
  }

  updateActiveObject = () => {
    setTimeout(() => {this.setState({selectedObject : this.state.canvas.getActiveObject()});}, 100);
  }

  onAddTextClick = () => {
    this.setState({
        objectArr: [...this.state.objectArr, <Text key={this.state.objectArr.length + 1} />],
        //selectedObject : <Text key={this.state.objectArr.length + 1} />
    });
  }

  buildFileSelector = () =>{
    console.log("buildFileSelector");
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    //fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.addEventListener("click", this.onAddImageClick);
    fileSelector.addEventListener("change", this.onAddImageChange);
    return fileSelector;
  }

  onAddImageClick = (e)=>{
    e.target.value = "";
  }

  onAddImageChange = (e)=>{
    console.log("onAddImageChange");
    let reader = new FileReader();
    let file = e.target.files[0];

    //console.log("file :: ",file);

    reader.onloadend = this.handleFile;
    reader.readAsDataURL(file);
  }

  handleFile = (e) => {
    console.log("result :: ");
    this.setState({
      objectArr: [...this.state.objectArr, <Image key={this.state.objectArr.length + 1} url={e.target.result} />],
    });
  }
  

  handleFileSelect = (e) => {
    e.preventDefault();
    this.fileSelector.click();
  }

  onFinishClick = (isFinish)=>{
    console.log("isFinish :: ",isFinish);
    if(isFinish && this.state.canvas !== null){
      console.log(this.state.canvas.toSVG());
      console.log(this.state.canvas.toSVG());
    }
  }

  copyElem = (elem)=>{
    console.log(elem);
    if(elem.type === 'text'){
      this.setState({
        objectArr: [...this.state.objectArr, <Text key={this.state.objectArr.length + 1} fontFamily={elem.fontFamily} fill={elem.fill} top={elem.top + 30} left={elem.left + 30} fontSize={elem.fontSize} fontWeight={elem.fontWeight} fontStyle={elem.fontStyle} textAlign={elem.textAlign} text={elem.text} flipX={elem.flipX} flipY={elem.flipY} angle={elem.angle} isCopy={true}/>],
      });
    }else{
      this.setState({
        objectArr: [...this.state.objectArr, <Image top={elem.top + 30} left={elem.left + 30} key={this.state.objectArr.length + 1} url={elem.getSrc()} originalPath={elem.originalPath} scaleX={parseFloat(elem.scaleX)} scaleY={parseFloat(elem.scaleY)} flipX={elem.flipX} flipY={elem.flipY} angle={elem.angle} isCopy={true}/>],
      });
    }
  }

  render() {
    
    //this.onFinishClick(this.props.isFinish);
    //console.log("design canvas render");

    const children = React.Children.map(this.state.objectArr, child => {
      return React.cloneElement(child, {
        canvas: this.state.canvas,
      })
    })
    
    const { width, height } = this.props;
    //if(this.state.canvas !== null) console.log("123...............",this.state.selectedObject);
      return (
        <div className="designarea-content">
          <div className="row">
            <div className="col-12 col-lg-6 canvas_elem d-flex justify-content-center align-items-center flex-wrap mb-lg-0 mb-4 order-1 order-lg-0 py-4 py-lg-0">
              <Fragment>
                <canvas id="Layer_1" ref={c => (this.c = c)} width={width} height={height} />
                {this.state.canvas && children}
              </Fragment>
            </div>
            <div className="col-12 col-lg-6 order-3 order-lg-0">
              <PropertyPanel copyElem={this.copyElem} onAddBgClick={this.onAddBgClick} onAddTextClick = {this.onAddTextClick} handleFileSelect = {this.handleFileSelect} getJsonData={this.props.getJsonData} sizeData={this.props.sizeData} canvas={this.state.canvas} selectedShape={this.props.selectedShape}></PropertyPanel>
            </div>
            
            {(this.state.canvas !== null && this.state.canvas.getActiveObject()) ? <ActiveObject selectedObject={this.state.selectedObject} canvas={this.state.canvas} fontData={this.props.getJsonData.config.font}></ActiveObject> : null}
            
          </div>
        </div>
      )
  }
}
export default DesignCanvas