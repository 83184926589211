import React from 'react';
import ItemsCarousel from 'react-items-carousel';
import '../Sizes/SizeItems.css';
import range from 'lodash/range';
export default class SizeItems extends React.Component {

  state = {
    children: [],
    activeItemIndex: 0,
    no_of_card : 7,
  }

  componentWillMount() {
    /*this.setState({
      children: [],
      activeItemIndex: 0,
    });

    setTimeout(() => {
      this.setState({
        children: this.createChildren(this.props.sizesData.sizes.length),
      })
    }, 100);*/
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  }

  /*createChildren = n => range(n).map((sizeobj,i) => <div sizevalue={sizeobj.title} onClick={this.onItemClick} key={i} className={
    (this.props.selectedSize === sizeobj.title ? "choose-items d-flex flex-wrap justify-content-center align-items-center active" : "choose-items d-flex flex-wrap justify-content-center align-items-center")}><div className="w-100 text-center font-weight-bold choose-sizes align-self-end">{sizeobj.title}</div>
  </div>);*/

  changeActiveItem = (activeItemIndex) => this.setState({ activeItemIndex });
  
  onWindowResize = ()=>{
    let windowResizeTimeOut;
    clearTimeout(windowResizeTimeOut);
    windowResizeTimeOut = setTimeout(function() {
      //console.log("called...",window.innerWidth);
      if(window.innerWidth >= 1199){
        this.setState({no_of_card : 7});
      }else if(window.innerWidth <= 1198 && window.innerWidth >= 768){
        this.setState({no_of_card : 6});
      }else if(window.innerWidth <= 767 && window.innerWidth >= 575){
        this.setState({no_of_card : 4});
      }else if(window.innerWidth <= 574 && window.innerWidth >= 480){
        this.setState({no_of_card : 3});
      }else if(window.innerWidth <= 479){
        this.setState({no_of_card : 2});
      }
    }.bind(this), 300);
  }

  onItemClick = (e) => {
    this.props.chageSize(e.target.getAttribute('sizevalue'));
  }

  render() {
    const {
      activeItemIndex,
      children,
      no_of_card,
    } = this.state;

    //console.log("no_of_card :: "+no_of_card);

    return (
      <ItemsCarousel
        // Placeholder configurations
        enablePlaceholder
        numberOfPlaceholderItems={5}
        minimumPlaceholderTime={1000}
        placeholderItem={<div style={{ height: 200, background: '#900' }}>Placeholder</div>}

        // Carousel configurations
        numberOfCards={no_of_card}
        gutter={12}
        showSlither={false}
        firstAndLastGutter={true}
        freeScrolling={false}

        // Active item configurations
        requestToChangeActive={this.changeActiveItem}
        activeItemIndex={activeItemIndex}
        activePosition={'center'}

        chevronWidth={60}
        rightChevron={<i className="flaticon-right"></i>}
        leftChevron={<i className="flaticon-left"></i>}
        outsideChevron={true}
      >
        {this.props.sizesData.sizes.map((sizeobj, i) => 
            //{if(this.props.selectedSize === sizeobj.title) ? boxClass.push("active") : boxClass.push("")}
            <div sizevalue={sizeobj.title} onClick={this.onItemClick} key={i} className={
              (this.props.selectedSize === sizeobj.title ? "choose-items d-flex flex-wrap justify-content-center align-items-center active" : "choose-items d-flex flex-wrap justify-content-center align-items-center")}><div className="w-100 text-center font-weight-bold choose-sizes align-self-end">{sizeobj.title}</div>
            </div>
          )}
      </ItemsCarousel>
    );  
  }
} 

//for display svg file//
/*<div className="choose-svg-box text-center w-100"><div id="mainLayout" className="position-relative text-center d-flex w-100 align-self-start align-items-center"><svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" version="1.1" width={parseFloat(sizeobj.title.split("x")[0])} height={parseFloat(sizeobj.title.split("x")[1])}><rect x="0" y="0" width="100%" height="100%" fill="#cccccc" rx="5" ry="5" /></svg></div></div><div className="w-100 text-center p-1 font-weight-bold choose-sizes align-self-end">{sizeobj.title}</div></div>*/
//for display svg file//

{/* <div>
        <ItemsCarousel
          gutter={12}
          activePosition={'center'}
          chevronWidth={60}
          numberOfCards={this.state.no_of_card}
          slidesToScroll={2}
          outsideChevron={true}
          showSlither={false}
          firstAndLastGutter={false}
          //activeItemIndex={this.state.activeItemIndex}
          //requestToChangeActive={value => this.setState({ activeItemIndex: value })}
          rightChevron={<i className="flaticon-right"></i>}
          leftChevron={<i className="flaticon-left"></i>}
          
        >
          {this.props.sizesData.sizes.map((sizeobj, i) => 
            //{if(this.props.selectedSize === sizeobj.title) ? boxClass.push("active") : boxClass.push("")}
            <div sizevalue={sizeobj.title} onClick={this.onItemClick} key={i} className={
              (this.props.selectedSize === sizeobj.title ? "choose-items d-flex flex-wrap justify-content-center align-items-center active" : "choose-items d-flex flex-wrap justify-content-center align-items-center")}><div className="w-100 text-center font-weight-bold choose-sizes align-self-end">{sizeobj.title}</div>
            </div>
          )}
        </ItemsCarousel>
      </div>   */}