import React, {Component} from 'react';
import './App.css';
import DnbWizard from '../DnbWizard/DnbWizard';



class App extends Component{
  state = {
    jsonDataValue : null,

    steps : [
      {
        stepName: "Shapes",
        stepIcon: "flaticon-shapes",
      },
      {
        stepName: "Sizes",
        stepIcon: "flaticon-sizes",
      },
      {
        stepName: "Material",
        stepIcon: "flaticon-material",
      },
      {
        stepName: "Extras",
        stepIcon: "flaticon-extra",
      },
      {
        stepName: "Customize",
        stepIcon: "flaticon-report",
      },
      {
        stepName: "Summary",
        stepIcon: "flaticon-summary",
      }
    ]
  }

componentDidMount = () =>  {
  let url = 'http://labeldemo.designnbuy.in/phpinfo.php';//'http://labeldemo.designnbuy.in/public/getjson.php';
    fetch(url,{
      method: "GET",
      /*headers: {
        "access-control-allow-origin" : "*",
        "Content-type": "application/json; charset=UTF-8"
      }*/})
    .then(response => response.json())
    .then(
      (result) => {
        console.log(result)
        this.setState({jsonDataValue:result})
      },
      (error) => {
          console.log("error :: "+error);
      }
    )
    
}

/*buildList = (data) => {
  this.setState({jsonDataValue:data});
}*/

render(){
  console.log(this.state.jsonDataValue);
  return(
      <div className="App">
        <DnbWizard tabs={this.state.steps} getJsonData={this.state.jsonDataValue} />
      </div>
    )
  }
}

export default App;

