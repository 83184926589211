import React from "react";

//const fabric = window.fabric;

class Settings extends React.Component {
    constructor(props){
        super(props);    
    }

    render() {
        return (
            <div className="order-2 order-lg-1 info-panel pt-3 pt-lg-0">
                <h3 className="title text-center font-weight-bold">Sticker Info</h3>
                <div className="form-group row mb-0 top-section">
                    <div className="col-md-6 text-detail">
                        <div className="size">
                            <label className="col-form-label"><h5>Size: </h5></label>
                            <label className="col-form-label ml-2">{this.props.sizeData.widthval + " X " + this.props.sizeData.heightval + " "+this.props.getJsonData.config.unit}</label>
                        </div>
                        <div className="shape">
                            <label className="col-form-label"><h5>Shape: </h5></label>
                            <label className="col-form-label ml-2">{this.props.selectedShape.shape_title}</label>
                        </div>
                    </div>
                    <div className="col-md-6 margin-detail">
                    <div className="red margin d-flex align-items-center"><span className="border-dashed mr-3 d-inline-block col-4"></span>Cut Margin</div>
                    <div className="black margin d-flex align-items-center"><span className="border-dashed mr-3 d-inline-block col-4"></span>Bleed Margin</div>
                    <div className="green margingreen margin d-flex align-items-center"><span className="border-dashed mr-3 d-inline-block col-4"></span>Safe Margin</div>
                    </div>
                </div>
                <p className="row h6 mt-3 mb-0 pb-3 pl-3 pr-3 border-bottom">NOTE: For best results, ensure your text and graphics are inside the Safe Area(green line) and background images intended to print to the beyond the Bleed Area(black line)</p>
            </div>
        );   
    }
}
export default Settings;