import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import '../Sizes/Sizes.css';
import SizeItems from '../Sizes/SizeItems';

import RectSvg  from '../ShapeSvgs/Rect';
import CustomSvg from "../ShapeSvgs/Custom";
import UploadCustomShape from '../Sizes/UploadCustomShape';

let actualwidth;
let actualheight;
//let avail_width = 650;
//let avail_height = 320;
let actualradious;
//let resetDisabled = "disabled";

class SecondStep extends React.Component {
    constructor(props) {
      super(props);
      
      this.state = {
        avail_width : 0,
        avail_height : 0,
        widthval : 0,
        heightval : 0,
        radiousval : 0,
        quantity : 0,
        strokeval : 4,
        strokefill : "#ff0000",
        fill : "#ffffff",
        selectedsize : null,
        showUploadCustomShape : false,
        customD : "M344.67,279.01c64.298,8.722,90.041-34.134,120.24-11.562c19.646,16.802-29.829,12.818,10.961,58.855	c36.631,41.34-54.376-8.105-114.566,16.071c-34.214,13.744-79.149,9.644-39.501-22.769	C346.096,299.751,280.371,270.287,344.67,279.01z"
      };
      
    }
    componentWillMount = () => {
      
      console.log("this.props.sizeData :: ",this.props.sizeData);
      if(this.props.sizeData === null){
        //console.log("this.props.selectedShape.radious :: ",this.props.selectedShape.radious);
        this.setState({widthval : parseFloat(this.props.selectedShape.default_size.split("x")[0])});
        this.setState({heightval : parseFloat(this.props.selectedShape.default_size.split("x")[1])});
        (this.props.selectedShape.radious !== undefined) ? this.setState({radiousval : parseFloat(this.props.selectedShape.radious)}) : this.setState({radiousval : ''});
        this.setState({selectedsize : this.props.selectedShape.default_size});
        
        if(this.props.selectedShape && this.props.selectedShape.shape_title === 'Custom'){this.setState({showUploadCustomShape : true});}
      }else{
        //console.log("this.props.sizeData.radiousval :: ",this.props.sizeData.radiousval);
        this.setState({widthval : this.props.sizeData.widthval});
        this.setState({heightval : this.props.sizeData.heightval});
        this.setState({radiousval : this.props.sizeData.radiousval});
        this.setState({selectedsize : this.props.sizeData.selectedsize});
        this.setState({quantity : this.props.sizeData.quantity});
        this.setState({customD : this.props.sizeData.customD});

        if(this.props.selectedShape && this.props.selectedShape.shape_title === 'Custom'){this.setState({showUploadCustomShape : false});}
      }
      this.onWindowResize();
      window.addEventListener('resize', this.onWindowResize);
      
    }

    onWindowResize = ()=>{
      let windowResizeTimeOut;
      clearTimeout(windowResizeTimeOut);
      windowResizeTimeOut = setTimeout(function() {
        //console.log("called...",window.innerWidth);
        if(window.innerWidth >= 1199){
          this.setState({avail_width:650});
          this.setState({avail_height:320});
        }else if(window.innerWidth <= 1198 && window.innerWidth >= 768){
          this.setState({avail_width:555});
          this.setState({avail_height:273});
        }else if(window.innerWidth <= 767 && window.innerWidth >= 575){
          this.setState({avail_width:460});
          this.setState({avail_height:226});
        }else if(window.innerWidth <= 574 && window.innerWidth >= 480){
          this.setState({avail_width:400});
          this.setState({avail_height:197});
        }else {
          this.setState({avail_width:260});
          this.setState({avail_height:128});
        }
      }.bind(this), 300);
    }

    onValidate = () => {
      return this.state.quantity === "" ? false : true;;
    }

    onNextClick = () => {
      return this.state;
    }

    onPreviousClick = () => {
      return this.state;
    }

    onWidthChange = (val) => {
      this.setState({widthval : parseFloat(val)});
    }

    onHeightChange = (val) =>{
      this.setState({heightval : parseFloat(val)});
    }

    onRadiousChange = (val) => {
      this.setState({radiousval : parseFloat(val)});
    }

    onQuantityChange = (val) => {
      //console.log("val :: "+val);
      this.setState({quantity:val});
      this.props.onQtySelect(val);
    }

    selectedSize = (size)=> {
      var selectedSize = size.split("x");
      this.setState({widthval : selectedSize[0],heightval : selectedSize[1]});
      this.setState({selectedsize : size});
    }

    onSwitchclick = () => {      
      this.setState({widthval : this.state.heightval, heightval : this.state.widthval});
    }

    onResetBtnClick = () => {
      this.selectedSize(this.state.selectedsize);
    }

    updateSvg = () => {
      if(this.props.getJsonData.config.unit === "mm"){
        actualwidth = this.state.widthval * 3.77;
        actualheight = this.state.heightval * 3.77;
        actualradious = this.state.radiousval * 3.77;
      }else{
        actualwidth = this.state.widthval;
        actualheight = this.state.heightval;
      }
      //update height and width as per box size//
      let size_ratio = actualwidth / actualheight;
      if(actualwidth > actualheight){
        actualwidth = this.state.avail_width;
        actualheight = this.state.avail_width / size_ratio;
        if(actualheight > this.state.avail_height){
          actualheight = this.state.avail_height;
          actualwidth = this.state.avail_height * size_ratio;
        }
      }else{
          actualheight = this.state.avail_height;
          actualwidth = this.state.avail_height * size_ratio;
          if(actualwidth > this.state.avail_width){
            actualwidth = this.state.avail_width;
            actualheight = this.state.avail_width / size_ratio;
          }
      }
      //update height and width as per box size//
    }

    createSvg = () =>{
      //console.log("this.state.customD :: ",this.state.customD);
      this.updateSvg();
      if(this.props.selectedShape != null){
        if(this.props.selectedShape.shape_title === "Rect"){
            return <div id="mainLayout"><RectSvg x={0} y={0} margin={0} width={actualwidth} height={actualheight} fill="#ffffff" strokeWidth="4" stroke="#ff0000" rx={actualradious} ry={actualradious}/></div> 
        }else if(this.props.selectedShape.shape_title === "Square"){
            return <div id="mainLayout"><RectSvg x={0} y={0} margin={0} width={actualwidth} height={actualheight} fill="#ffffff" strokeWidth="4" stroke="#ff0000" rx={actualradious} ry={actualradious}/></div> 
        }else if(this.props.selectedShape.shape_title === "Circle"){
            return <div id="mainLayout"><CustomSvg margin={0} ratio_to_fit={0} width={actualwidth} height={actualheight} d='M652.006,518.029c24.284,0.001,43.97,19.688,43.97,43.972s-19.687,43.97-43.972,43.97  c-24.283,0-43.97-19.688-43.969-43.972c0-24.283,19.686-43.969,43.969-43.97H652.006z' stroke="#ff0000" strokeWidth="4" fill="#ffffff"/></div>
        }else if(this.props.selectedShape.shape_title === "Oval"){
            return <div id="mainLayout"><CustomSvg ratio_to_fit={0}  margin={0} width={actualwidth} height={actualheight} d='M392.006,258.5c48.325,0,87.5,19.477,87.499,43.501S440.329,345.5,392.004,345.5s-87.5-19.476-87.499-43.5  c0-24.024,39.175-43.499,87.499-43.5H392.006z' fill="#ffffff" strokeWidth="4" stroke="#ff0000"/></div> 
        }else if(this.props.selectedShape.shape_title === "Diamond"){
            return <div id="mainLayout"><CustomSvg width={actualwidth} height={actualheight} ratio_to_fit={0} margin={0} fill="#ffffff" strokeWidth="4" stroke="#ff0000" d="M696,410.5l87.579,43.77L696,498.04l-87.58-43.771L696,410.5z"/></div>
        }else if(this.props.selectedShape.shape_title === "Custom"){
            return <div id="mainLayout"><CustomSvg ratio_to_fit={0} margin={0} width={actualwidth} height={actualheight} d={this.state.customD} stroke="#ff0000" strokeWidth="4" fill="#ffffff"/></div>
        }
      }
    }

    onCloseCustomShapeClick = ()=>{
      this.setState({showUploadCustomShape : false});
    }

    updateShape = (d)=>{
      //console.log("d :: ",d);
      this.setState({customD : d});
      this.setState({showUploadCustomShape:false});
    }
    
    render() {
      let radiousStyle = this.props.selectedShape.radious && this.props.selectedShape.radious !== null? {display : "block"} : {display : "none"};
      let selectedSize = this.state.widthval + "x" + this.state.heightval;

      //console.log("this.state.quantity :: ",this.state.quantity);

      return(
        <div className="tabs-content-wrapper">
              {(this.state.showUploadCustomShape) ? <UploadCustomShape onCloseClick={this.onCloseCustomShapeClick} d={this.updateShape}></UploadCustomShape> : null}
              <div className="tab-title col-8 col-md-12 mx-auto p-0 text-center mb-4">
                  <h2 className="position-relative text-center d-inline-block text-uppercase m-0">choose suggested sizes</h2>
              </div>
              <div className="choose-sizes-wrapper mb-5 px-5">
              <SizeItems sizesData={this.props.selectedShape} chageSize={this.selectedSize} selectedSize={selectedSize}/>
              </div>
              <div className="tab-title col-8 col-md-12 mx-auto p-0 text-center mb-4">
                  <h2 className="position-relative text-center d-inline-block text-uppercase m-0">or choose your custom sizes</h2>
              </div>
              <div className="tabs-inner-content">
                <div className="row">
                  <div className="col-xl-9 col-lg-8 col-md-12 order-lg-2">
                    <div className="svg_size_content d-flex justify-content-center align-items-center w-100">
                      {this.createSvg()}
                    </div>
                    <div className="quantity-box d-lg-flex flex-lg-row justify-content-lg-end mt-2 mt-lg-0 mb-4 mb-lg-0 mx-auto">
                      <label className="d-lg-flex align-items-lg-center">Quantity :</label>
                      <input className="form-control" min="0" max="999" value={this.state.quantity} type="number" name="quantityinput" onChange={evt => this.onQuantityChange(evt.target.value)} />
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-12 d-lg-flex align-items-lg-center order-lg-1">
                      <div className="custom-sizes position-relative col-lg-12 col-md-8 p-0 mx-auto">
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                                <div className="form-group mb-4">
                                  <div className="row d-flex align-items-baseline">
                                    <div className="col-lg-7 col-9 align-self-end">
                                        <label>Width</label>
                                        <input className="form-control" value={this.state.widthval} type="number" name="widthinput" onChange={evt => this.onWidthChange(evt.target.value)}/>
                                    </div>
                                    <div className="col-lg-5 col-3 align-self-end">
                                        <label>{this.props.getJsonData.config.unit}</label>
                                    </div>
                                  </div>

                              </div>
                              <div className="form-group mb-4">
                                  <div className="row d-flex align-items-baseline">
                                    <div className="col-lg-7 col-9 align-self-end">
                                      <label>Height</label>
                                      <input className="form-control" value={this.state.heightval} type="number" name="heightinput" onChange={evt => this.onHeightChange(evt.target.value)}/>
                                    </div>
                                    <div className="col-lg-5 col-3 align-self-end">
                                        <label>{this.props.getJsonData.config.unit}</label>
                                    </div>
                                  </div>
                              </div>
                              <div className="reverse-block position-absolute">
                                  <span className="icon-block" onClick={this.onSwitchclick}><i className="flaticon-refresh-btn"></i></span>
                              </div>
                            </div>
                          </div>
                           
                          {this.props.selectedShape.radious && this.props.selectedShape.radious !== null &&
                            <div className="form-group mb-4" style={radiousStyle}>
                                <div className="row d-flex align-items-baseline">
                                  <div className="col-lg-7 col-9 align-self-end">
                                      <label>Corner Radius</label>
                                      <input className="form-control" value={this.state.radiousval} type="number" name="radiousinput" onChange={evt => this.onRadiousChange(evt.target.value)}/>
                                  </div>
                                  <div className="col-lg-5 col-3 align-self-end">
                                      <label>{this.props.getJsonData.config.unit}</label>
                                  </div>
                                </div>
                            </div>
                        }
                          {/* <div className="form-group mb-4" style={radiousStyle}>
                              <div className="row d-flex align-items-baseline">
                                <div className="col-lg-6 col-9 align-self-end">
                                    <label>Corner Radius</label>
                                    <input className="form-control" value={this.state.radiousval} type="text" name="radiousinput" onChange={evt => this.onRadiousChange(evt.target.value)}/>
                                </div>
                                <div className="col-lg-6 col-3 align-self-end">
                                    <label>{this.props.getJsonData.config.unit}</label>
                                </div>
                              </div>

                          </div> */}

                          <button type="reset" className="btn btn-primary" onClick={this.onResetBtnClick}>Reset</button>
                        </form>
                    </div>
                  </div>
                  
                </div>
              </div>
        </div>
      ) 
    }
  }
export default SecondStep;



