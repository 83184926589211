import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import ExtraItems from './ExtrasItems';

let selectedExtraOptionData;
let extraOptionData = null;
class FourStep extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        selectedExtraOptionData : null
      }
    }

    

    onValidate() {
      //return extraOptionData === undefined ? true : extraOptionData.length === selectedExtraOptionData.length ? true : false;
      return true;
    }

    renderExtraOptions = () => {
      let materialName = this.props.materialData.selectedMaterial.getAttribute("materialname");
      let sheetName = this.props.materialData.selectedMaterial.getAttribute("value");
      
      //let extraOptionData = null;
      this.props.getJsonData.material.map((optionsData) => {
        if(optionsData.material === materialName){
          optionsData.values.map(materialValue => {
            if(materialValue.sheet === sheetName){
              extraOptionData = materialValue.extra_option;
            }
          })
        }
      })

      return extraOptionData;
    }

    selectedExtraData = (selectedData) => {
      this.setState({selectedExtraOptionData : selectedData});
      //selectedExtraOptionData = selectedData;
    }

    onNextClick = () => {
      //return selectedExtraOptionData;
      return this.state.selectedExtraOptionData;
    }
  
    onPreviousClick = () => {
      //return selectedExtraOptionData;
      return this.state.selectedExtraOptionData;
    }

    render() {
      //console.log("Extra state :: ",this.state.selectedExtraOptionData);
      return(
        <div className="tabs-content-wrapper">
          <div className="tab-title col-8 col-lg-12 mx-auto p-0 text-center mb-4">
              <h2 className="position-relative text-center d-inline-block text-uppercase m-0">Select any finishes or extras you might need</h2>
          </div>
          <div className="tabs-inner-content">
            <div className="material-choose-wrapper text-left">
              <div className="row">
                <ExtraItems extraOptionData={this.renderExtraOptions()} selectedExtraData={this.selectedExtraData} extraData={this.props.extraData}></ExtraItems>
              </div>
            </div>
          </div>
        </div>
      ) 
    }
  }
export default FourStep;



