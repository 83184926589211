import React from 'react';
import PropTypes from 'prop-types';


const fabric = window.fabric

class Text extends React.Component {
  static propTypes = {
    canvas: PropTypes.object,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
    height: PropTypes.number.isRequired,
    fill: PropTypes.string.isRequired,
  }

  static defaultProps = {
    top: 0,
    left: 0,
    width: 50,
    height: 50,
    fill: 'black',
    fontFamily : 'Times New Roman',
    fontSize: 30,
    fontWeight : 'normal',
    fontStyle : 'normal',
    textAlign : 'left',
    text : 'Your text here',
    flipX : false,
    flipY : false,
    angle : 0,
    isCopy : false
  }

  componentDidMount() {
    //console.log("dimensionAffectingProps ",this.props.dimensionAffectingProps);
    //const text = new fabric.IText('Tap and Type');
    const text = new fabric.Text('Your text here',this.props);

    text.set({
      transparentCorners: false,
      cornerStyle: 'circle',
      cornerColor: '#009FDD',
      rotationCursor: 'grab',
    })

    text.setControlVisible("mt", false);
    text.setControlVisible("ml", false);
    text.setControlVisible("mr", false);
    text.setControlVisible("mb", false);
    text.set("rotatingPointOffset",30);

    this.props.canvas.add(text);
    if(!this.props.isCopy){
      this.props.canvas.centerObject(text);
    }
    this.props.canvas.discardActiveObject();
    this.props.canvas.setActiveObject(text);
  }

  render() {
    return null
  }
}

export default Text
