import React from "react";
import Alert from '../DesignTool/Assets/Alert';
import CustomizeButton from '../DesignTool/Assets/CustomizeButton';

class UploadCustomShape extends React.Component {

    componentDidMount() {
        this.fileSelector = this.buildFileSelector();
    }

    state = {
        showAlert : false
    }

    onCustomSvgUpload = (e)=>{
        //let reader = new FileReader();
        //reader.readAsText(file);

        let reader = new FileReader();
        let file = e.target.files[0];
        reader.readAsText(file);
        reader.onload = function(){
            let str = reader.result;
            let parser = new DOMParser();
            let doc = parser.parseFromString(str, "image/svg+xml");
            //console.log("first child :: ",doc.firstChild);
            //console.log("doc.firstChild.childNodes[0] :: ",doc.firstChild.childNodes[0]);
            if(doc && doc.firstChild && doc.firstChild.childNodes[0] && doc.firstChild.childNodes[0].tagName === "path"){
                this.props.d(doc.firstChild.childNodes[0].getAttribute("d"));
            }else if(doc && doc.firstChild && doc.firstChild.childNodes[1] && doc.firstChild.childNodes[1].tagName === "path"){
                this.props.d(doc.firstChild.childNodes[1].getAttribute("d"));
            }else{
                this.setState({showAlert:true});
            }
            
        }.bind(this)

        reader.onerror = function() {
            console.log(reader.error);
        };

    }

    onDownloadSampleClick = (e)=>{
        let svg = '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="145px" height="145px" viewBox="0 0 145 145" enable-background="new 0 0 100 100" xml:space="preserve"><path d="M70.33952,35.80746C99.34181,-52.10137 212.96642,35.80746 70.33952,148.8372C-72.28889,35.80746 41.33672,-52.10086 70.33952,35.80746z" fill="#fff" stroke-width="4" stroke="#000000"></path></svg>';

        e.target.setAttribute('href', 'data:svg;charset=utf-8,' + encodeURIComponent(svg));
        e.target.setAttribute("download", "sample.svg");
        e.target.setAttribute("target", "_blank");
    }

    onOkClick = ()=>{
        this.setState({showAlert:false});
    }

    handleFileSelect = (e)=>{
        e.preventDefault();
        this.fileSelector.click();
    }

    buildFileSelector = () =>{
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', '.svg');
        fileSelector.addEventListener("change", this.onCustomSvgUpload);
        return fileSelector;
    }

    /*onUploadSvgClick = (e)=>{
        let reader = new FileReader();
        let file = e.target.files[0];
        
        reader.onload = function(){
            let str = reader.result;
            let parser = new DOMParser();
            let doc = parser.parseFromString(str, "image/svg+xml");
            //console.log("first child :: ",doc.firstChild);
            //console.log("doc.firstChild.childNodes[0] :: ",doc.firstChild.childNodes[0]);
            if(doc && doc.firstChild && doc.firstChild.childNodes[0] && doc.firstChild.childNodes[0].tagName === "path"){
                this.props.d(doc.firstChild.childNodes[0].getAttribute("d"));
            }else if(doc && doc.firstChild && doc.firstChild.childNodes[1] && doc.firstChild.childNodes[1].tagName === "path"){
                this.props.d(doc.firstChild.childNodes[1].getAttribute("d"));
            }else{
                this.setState({showAlert:true});
            }
            
        }.bind(this)

        reader.onerror = function() {
            console.log(reader.error);
        };

        reader.readAsDataURL(file);    
    }*/

    render() {
        const modalStyle = {
            background: 'rgba(0,0,0,0.5)'
        };
        return (
            <div>
                <div className="modal d-flex position-absolute align-content-center align-items-center" style={modalStyle}>
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header py-2">
                                <h5 className="modal-title">Upload Custom Shape</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onCloseClick}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <p><span className="h6 font-weight-bold">SVG File Instructions:<br/></span>
                                    - We support custom shapes in SVG file format. <br/>
                                    - Click on "Download Sample SVG" button to download sample SVG file for reference. <br/>
                                    - As indicated in sample SVG, your SVG file should only contain the shape path.
                                </p>
                                {/* <div className="custom-file-upload-btn mb-3">
                                    <input type="file" accept=".svg" onChange={(e)=> { this.onCustomSvgUpload(e.target.files[0]) }}/>
                                </div> */}
                                <CustomizeButton icon="fa fa-upload" name="Upload SVG" onClick={this.handleFileSelect}></CustomizeButton>
                                <a href="#" onClick={this.onDownloadSampleClick}>Download Sample SVG</a>
                            </div>
                        
                        </div>
                    </div>
                </div>
                {(this.state.showAlert) ? <Alert onClick={this.onOkClick} msg="This is not proper SVG file.To know more please click on download sample SVG and edit it."></Alert> : null}
            </div>
        );   
    }
}
export default UploadCustomShape;