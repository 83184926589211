import React from "react";
import "./ToggleButton.css";

class ToggleButton extends React.Component {
  constructor(props){
    super(props);    
  }

  render() {
    let myclass,mystyle,isActive;
    isActive = (this.props.active === true) ? "active" : "" 
    myclass = (this.props.name === "fill") ? "btn rounded-0 border-right py-1 px-2 px-lg-2 px-xl-2 color-fill " + isActive : "btn rounded-0 px-3 px-lg-2 px-xl-3 border-right "+isActive;
    mystyle = (this.props.name === "fill") ? {color: this.props.fill} : {};
    
    return (
        <div className="font-style-btn d-flex">
            <button value={this.props.name} className={myclass} onClick={this.props.onClick}>
                <i className={this.props.icon} style={mystyle}></i>
            </button>
        </div>
    );
  }
}
export default ToggleButton;



