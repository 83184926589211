import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import FontFaceObserver from "fontfaceobserver";
import './ActiveObject.css';
import ToggleButton from './Assets/ToggleButton';
import { SketchPicker } from 'react-color';
import ImageCrop from './ImageCrop';
import CloseButton from './Assets/CloseButton';

const fabric = window.fabric
class ActiveObject extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text : "",
            fontData: [],
            fontFamily : "Times New Roman",
            fontSize : 40,
            isBold : false,
            isItalic : false,
            textLeftAlign : false,
            textCenterAlign : false,
            textRightAlign : false,
            openFillClr : false,
            fill : "#000000",
            changeSelection : false,
            imageScale : 1,
            cropWindow : false
        };
        this.onFontFamilyChange = this.onFontFamilyChange.bind(this);
    }

    componentDidMount() {
        //console.log(".......................did mount called.........................");
        // if(this.props.canvas !== null && this.props.canvas.getActiveObject()){
        //     this.setState({text:this.props.canvas.getActiveObject().text});
        // }
        console.log("fontData :: ",this.props.fontData);
        this.setState({fontData: this.props.fontData});
    }

    onFontFamilyChange = (e) => {
        if (e.target.getAttribute('value') !== 'Times New Roman') {
        //console.log("e.target.getAttribute('value') :: ",e.target.getAttribute('value'));
        //if (e.target.getAttribute('value') !== 'Aleo') {
            this.loadAndUse(e.target.getAttribute('value'));
        } else {
            this.props.canvas.getActiveObject().set("fontFamily", e.target.getAttribute('value'));
            this.props.canvas.renderAll();
        }
        this.setState({fontFamily : e.target.getAttribute('value')});
    }

    loadAndUse = (font) => {
        let myfont = new FontFaceObserver(font)
        myfont.load()
        .then(function() {
            // when font is loaded, use it.
            this.props.canvas.getActiveObject().set("fontFamily", font);
            this.props.canvas.renderAll();
            this.setState({fontFamily : font});
        }.bind(this)).catch(function(e) {
            console.log(e)
            alert('font loading failed ' + font);
        });
    }

    textInputChange = (e) => {
        this.setState({text : e.target.value});
        this.props.canvas.getActiveObject().set("text",e.target.value);
        this.props.canvas.renderAll();
    }

    onBoldClick = () =>{
        //let bold = (!this.state.isBold) ? "bold" : ""
        let bold = (this.props.canvas.getActiveObject().fontWeight !== "bold") ? "bold" : ""
        this.props.canvas.getActiveObject().set("fontWeight",bold);
        this.props.canvas.renderAll();
        this.setState({isBold : !this.state.isBold});
    }

    onItalicClick = () =>{
        //let italic = (!this.state.isItalic) ? "italic" : ""
        let italic = (this.props.canvas.getActiveObject().fontStyle !== "italic") ? "italic" : ""
        this.props.canvas.getActiveObject().set("fontStyle",italic);
        this.props.canvas.renderAll();
        this.setState({isItalic : !this.state.isItalic});
    }

    onTextAlignClick = (e) =>{
        if(e.target.value === 'left'){
            this.props.canvas.getActiveObject().set('textAlign', 'left');
            this.setState({
                textLeftAlign : !this.state.textLeftAlign,
                textCenterAlign : false,
                textRightAlign : false
            });
        }else if(e.target.value === 'center'){
            this.props.canvas.getActiveObject().set('textAlign', 'center');
            this.setState({
                textLeftAlign : false,
                textCenterAlign : !this.state.textCenterAlign,
                textRightAlign : false
            });
        }else if(e.target.value === 'right'){
            this.props.canvas.getActiveObject().set('textAlign', 'right');
            this.setState({
                textLeftAlign : false,
                textCenterAlign : false,
                textRightAlign : !this.state.textRightAlign
            });
        }
        this.props.canvas.renderAll();
    }

    onFillClick = () =>{
        this.setState({openFillClr : !this.state.openFillClr});
    }

    onFillClrChange = (val)=>{
        this.props.canvas.getActiveObject().set('fill',val);
        this.props.canvas.renderAll();
        this.setState({fill : val});
    }

    onFontSizeChange = (e) =>{
        console.log(e.target.value);
        if(e.target.value < 0){
            e.target.value = 0;
        }else if(e.target.value > 200){
            e.target.value = 200;
        }else{
            e.target.value = e.target.value;
        }
        this.props.canvas.getActiveObject().set("fontSize", e.target.value);
        this.props.canvas.renderAll();
        this.setState({fontSize : e.target.value});
    }

    onImageScale = (e)=>{
        //console.log(e.target.value);
        this.props.canvas.getActiveObject().set({
            'scaleX': e.target.value,
            'scaleY': e.target.value
        });
        this.props.canvas.getActiveObject().setCoords();
        this.props.canvas.renderAll();
        this.setState({ imageScale: e.target.value });
    }

    /*onCropImageCancel = () => {
        this.setState({cropWindow: false});
    }*/
  
    onCropImgClick = (evt) => {
        this.setState({cropWindow: true});
    }

    applyCropImg = (url) => {
        /*console.log("url :: ",url);
        this.props.canvas.getActiveObject().setSrc(url);
        this.props.canvas.renderAll();
        this.setState({cropWindow: false});*/
        let selectedObj = this.props.canvas.getActiveObject();
        selectedObj.setSrc(url,
            () => {
                //this.props.canvas.add(this.props.canvas.getActiveObject());
                selectedObj.setCoords();
                this.props.canvas.renderAll();
                this.setState({cropWindow: false});
            });
        /*this.props.canvas.discardActiveObject(); 
        this.props.canvas.setActiveObject(selectedObj);
        this.props.canvas.renderAll();*/
    }

    cancelCropImg = (display)=>{
        this.setState({cropWindow:display});
    }

    closeFillColor = ()=>{
        this.setState({openFillClr:false});
    }

    render() {
        const { fontData } = this.state;
        let fontList = this.state.fontData.length > 0
            && fontData.map((item, i) => {
            return (
                <li className="dropdown-item border-bottom pl-3 pr-3" onClick={this.onFontFamilyChange} style={{fontFamily : item}}  key={i} value={item}>{item}</li>
            )
        }, this);  

        let bold,italic,textLeftAlign,textCenterAlign,textRightAlign,fontSize,fill,fontFamily,text,objectType,imgPath,imgScale;
        
        if(this.props.canvas !== null && this.props.canvas.getActiveObject()){
            //console.log(this.props.canvas.getActiveObject());
            objectType = this.props.canvas.getActiveObject().type;
            //console.log("objectType :: "+objectType);
            if(objectType === 'text'){
                fontFamily = this.props.canvas.getActiveObject().fontFamily;
                text = this.props.canvas.getActiveObject().text;
                fill = this.props.canvas.getActiveObject().fill;
                //console.log("fill :: ",fill);
                bold = (this.props.canvas.getActiveObject().fontWeight === 'bold') ? true : false;
                italic = (this.props.canvas.getActiveObject().fontStyle === 'italic') ? true : false;
                textLeftAlign = (this.props.canvas.getActiveObject().textAlign === 'left') ? true : false;
                textCenterAlign = (this.props.canvas.getActiveObject().textAlign === 'center') ? true : false;
                textRightAlign = (this.props.canvas.getActiveObject().textAlign === 'right') ? true : false;
                //console.log("bold.... :: "+bold);
                //for proportion font size//
                if(this.props.canvas.getActiveObject().scaleY === 1){
                    this.props.canvas.getActiveObject().fontSize *= this.props.canvas.getActiveObject().scaleX;
                }else {
                    this.props.canvas.getActiveObject().fontSize *= this.props.canvas.getActiveObject().scaleY;
                }
                this.props.canvas.getActiveObject().fontSize = this.props.canvas.getActiveObject().fontSize.toFixed(0);
                this.props.canvas.getActiveObject().scaleX = 1;
                this.props.canvas.getActiveObject().scaleY = 1;
                //for proportion font size//
                fontSize = this.props.canvas.getActiveObject().fontSize;

            }else{
                //imgPath = this.props.canvas.getActiveObject().url;//url//src//getSrc()
                imgPath = (this.props.canvas.getActiveObject().originalPath) ? this.props.canvas.getActiveObject().originalPath : this.props.canvas.getActiveObject().getSrc()
                //console.log("imgPath :: "+imgPath);
                imgScale = this.props.canvas.getActiveObject().scaleX;
            }

            //console.log("imgPath ",imgPath);
            
            if (objectType === 'text') {
                return(
                    <div className="col-12 col-lg-6 order-2 order-lg-0 mb-lg-0 mb-4">
                        <div className="textedit-panel">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="textedit-panel-top border-bottom position-relative">
                                        <div className="row no-gutters justify-content-center">
                                            <div className="col-10 col-md-4 col-lg-5 col-xl-4 font-family-box">
                                                <div className="font-styles p-2">
                                                    <div className="dropup show w-100">
                                                        <span className="dropdown-toggle form-control form-control-sm" id="fontStyleDropdownMenu" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{fontFamily : fontFamily}}>{fontFamily}</span>
                                                        <ul className="p-0 m-0 dropdown-menu rounded-0 w-100" aria-labelledby="fontStyleDropdownMenu">{fontList}</ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="border-right font-size-box position-relative w-100">
                                                <div className="font-size py-2 pr-2">
                                                    <input className="form-control form-control-sm pl-1 pr-0" value={fontSize} type="number" min="1" max="200" onChange={this.onFontSizeChange}/>
                                                </div>
                                            </div>
                                            <ToggleButton name="bold" icon="fa fa-bold" onClick={this.onBoldClick} active={bold}></ToggleButton>
                                            <ToggleButton name="italic" icon="fa fa-italic" onClick={this.onItalicClick} active={italic}></ToggleButton>
                                            <ToggleButton name="fill" icon="fa fa-square" onClick={this.onFillClick} fill={ fill }></ToggleButton>
                                            {(this.state.openFillClr) ? <div className="color-picker-box"><SketchPicker disableAlpha={true} color={ this.state.fill } onChangeComplete={evt => this.onFillClrChange(evt.hex)}></SketchPicker><CloseButton onClick={this.closeFillColor}></CloseButton></div> : ""}
                                            <ToggleButton name="left" icon="fa fa-align-left" onClick={this.onTextAlignClick} active={textLeftAlign}></ToggleButton>
                                            <ToggleButton name="center" icon="fa fa-align-center" onClick={this.onTextAlignClick} active={textCenterAlign}></ToggleButton>
                                            <ToggleButton name="right" icon="fa fa-align-right" onClick={this.onTextAlignClick} active={textRightAlign}></ToggleButton>
                                        </div>
                                    </div>
                                    <div className="textedit-panel-bottom p-2">
                                        <div className="form-group m-0">
                                            <textarea className="w-100 form-control form-control-sm" rows="2" type="text" value={text} onChange={this.textInputChange}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) 
            }else{
                return(
                    <div className="col-12 col-lg-6 order-2 order-lg-0 mb-lg-0 mb-4">
                        <div className="imgedit-panel">
                            <ImageCrop cropWindow={this.state.cropWindow} imgPath={imgPath} onApply={this.applyCropImg} onCancel={this.cancelCropImg}></ImageCrop>
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="p-3">
                                        <div className="form-group row align-items-center">
                                            <label className="col-md-3 col-lg-4 col-xl-3 col-form-label pt-0">Image Scale: </label>
                                            <div className="col-md-9 col-lg-8 col-xl-9 pl-md-0">
                                                <input type="range" className="custom-range" value = {imgScale} min={0.1} max={1} step={0.1} onChange = {evt => this.onImageScale(evt)} ></input>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary text-capitalize rounded-sm w-100 crop-btn" onClick = {evt => this.onCropImgClick(evt)} >
                                            <i className="fa fa-crop"></i> Crop image
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }else{
            return(
                <div>No object selected</div>
            )
        }
       
    }
  }
export default ActiveObject;