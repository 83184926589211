import React from "react";
//import "../DesignTool/ActiveObject.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import "../PropertyPanel/PropertyPanel.css";
import ToolButton from '../Assets/ToolButton';
import CustomizeButton from '../Assets/CustomizeButton';
import Settings from "../Settings";
import { SketchPicker } from 'react-color';
import AlignPanel from '../AlignPanel';
import CloseButton from '../Assets/CloseButton';

class PropertyPanel extends React.Component {
    state = {
        background: '#fff',
        displayColorPicker: false,
        actionType : 'ungroup',
        showAlignPanel : false
    };

    onBgColorChange = (val) => {
        let clipPath = this.props.canvas.clipPath;
        clipPath.set('fill',val);
        clipPath.set('stroke',val);
        this.setState({ background: val });
        this.props.canvas.renderAll();
    };

    onAddBgClick = () => {
        (this.state.displayColorPicker === false) ? this.setState({ displayColorPicker: true }) : this.setState({ displayColorPicker: false });
    }

    setAction = (action) => {
        this.setState({actionType : action});
        if(action === 'align'){
            this.setState({showAlignPanel : !this.state.showAlignPanel});
        }
    }

    closeBgColor = () =>{
        this.setState({displayColorPicker:false});
    }

    closeAlignPanel = ()=>{
        this.setState({showAlignPanel:false});
    }

    render() {
        //console.log("this.state.actionType :: "+this.state.actionType);
        //console.log("render property panel");
        

        return (
            <div className="card">
                <div className="card-body pt-2 pl-3 pr-3 pb-0 d-flex flex-wrap">
                    <Settings getJsonData={this.props.getJsonData} selectedShape={this.props.selectedShape} sizeData={this.props.sizeData} ></Settings>
                    <div className="row order-1 order-lg-2">
                        <div className="col-12 col-md-6 tools">
                            <h4 className="title text-center font-weight-bold mb-3 mt-2">Tools</h4>
                            <div className="form-group btn-group-sm flex-wrap">
                                <ToolButton className="flaticon-flip-h" name="flip-X" canvas={this.props.canvas} disabled={(this.props.canvas !== null && this.props.canvas.getActiveObject()) ? "" : "disabled" }></ToolButton>
                                <ToolButton className="flaticon-flip-v" name="flip-Y" canvas={this.props.canvas} disabled={(this.props.canvas !== null && this.props.canvas.getActiveObject()) ? "" : "disabled" }></ToolButton>
                                <ToolButton className="fa fa-files-o" name="copy" canvas={this.props.canvas} disabled={(this.props.canvas !== null && this.props.canvas.getActiveObject()) ? "" : "disabled" } copyElem={this.props.copyElem}></ToolButton>
                                <ToolButton className="fa fa-clone" name="front" canvas={this.props.canvas} disabled={(this.props.canvas !== null && this.props.canvas.getActiveObject()) ? "" : "disabled" }></ToolButton>
                                <ToolButton className="back fa fa-clone" name="back" canvas={this.props.canvas} disabled={(this.props.canvas !== null && this.props.canvas.getActiveObject()) ? "" : "disabled" }></ToolButton>
                                <ToolButton className="flaticon-align-left" name="align" canvas={this.props.canvas} actiontype={this.setAction} disabled={(this.props.canvas !== null && this.props.canvas.getActiveObject()) ? "" : "disabled" }></ToolButton>
                                <ToolButton className="fa fa-times" name="delete" canvas={this.props.canvas} disabled={(this.props.canvas !== null && this.props.canvas.getActiveObject()) ? "" : "disabled" }></ToolButton>
                                <ToolButton className="fa fa-trash" name="trash" canvas={this.props.canvas}></ToolButton>
                            </div>
                            {(this.state.showAlignPanel && this.props.canvas !== null && this.props.canvas.getActiveObject()) ? <div className="text-align-panel position-relative"><AlignPanel canvas={this.props.canvas}/><CloseButton onClick={this.closeAlignPanel}></CloseButton></div> : null}
                        </div>

                        <div className="col-12 col-md-6 customize text-center">
                            <h4 className="title text-center font-weight-bold mb-3 mt-2">Customize</h4>
                            <CustomizeButton icon="fa fa-font" name="Add Text" onClick={this.props.onAddTextClick}></CustomizeButton>
                            <CustomizeButton icon="fa fa-picture-o" name="Add Image" onClick={this.props.handleFileSelect}></CustomizeButton>
                            <CustomizeButton icon="fa fa-paint-brush" name="Background" onClick={this.onAddBgClick}></CustomizeButton>
                            { this.state.displayColorPicker ? <div className="color-picker-box"><SketchPicker disableAlpha={true} color={ this.state.background } onChangeComplete={evt => this.onBgColorChange(evt.hex)}/><CloseButton onClick={this.closeBgColor}></CloseButton></div> : null }
                        </div>

                       
                </div>
            </div>
        </div>
        );
         
    }
}
export default PropertyPanel;
{/* <ToolButton className="fa fa-object-group" name="group" canvas={this.props.canvas} actiontype={this.setAction} disabled={(this.props.canvas !== null && this.props.canvas.getActiveObject()) ? (this.state.actionType === "group") ? "disabled" : "" : "disabled"}></ToolButton>
                                <ToolButton className="flaticon-ungroup" name="ungroup" canvas={this.props.canvas} actiontype={this.setAction} disabled={(this.state.actionType === "ungroup") ? "disabled" : "" }></ToolButton> */}
{/* <ToolButton className="fa fa-undo" name="undo" canvas={this.props.canvas}></ToolButton>
    <ToolButton className="fa fa-repeat" name="redo" canvas={this.props.canvas}></ToolButton> */}
{/* <ToolButton className="fa fa-eye" name="preview" selectedObject={this.props.selectedObject} canvas={this.props.canvas}></ToolButton> */}
{/* <ToolButton className="fa fa-scissors" name="cut" selectedObject={this.props.selectedObject} canvas={this.props.canvas}></ToolButton> */}
{/* <ToolButton className="fa fa-clipboard" name="paste" selectedObject={this.props.selectedObject} canvas={this.props.canvas}></ToolButton> */}
{/* <ToolButton className="fa fa-question" name="help" selectedObject={this.props.selectedObject} canvas={this.props.canvas}></ToolButton> */}
                                