import React from "react";

const fabric = window.fabric;

class ToolButton extends React.Component {
  constructor(props){
    super(props);    
    this.state = {
      flipX : true,
      flipY : true
    }
    this.onToolButtonClick = this.onToolButtonClick.bind(this);
  }

  onToolButtonClick = (e) => {
    let action = e.target.value;
    let selectedObject = this.props.canvas.getActiveObject();
    
    if(action === 'trash'){
      this.props.canvas.getObjects().map((obj, index) => (
        (index > 0) ? this.props.canvas.remove(obj) : ""
      ));
      this.props.canvas.renderAll();
    }
    
    if(action && selectedObject){
      switch (action) {
        case 'delete':
          this.props.canvas.remove(selectedObject);
          break;
        case 'group':
          if(selectedObject._objects){
            selectedObject.toGroup();
            this.props.actiontype('group');
          }
          break;
        case 'ungroup':
          if(selectedObject.type === "group"){
            let items = selectedObject._objects;
            selectedObject._restoreObjectsState();
            this.props.canvas.remove(selectedObject);
            
            items.map((obj, index) => (
              this.props.canvas.add(obj) , this.props.canvas.item(this.props.canvas.size()-1).hasControls = true
            ));
            this.props.actiontype('ungroup');
          }
          break;
        case 'copy':
          // let cloneObj = fabric.util.object.clone(selectedObject);
          // cloneObj.set("top", cloneObj.top + 30);
          // cloneObj.set("left", cloneObj.left + 30);
          // //this.props.canvas.discardActiveObject();
          // this.props.canvas.add(cloneObj);
          // this.props.canvas.setActiveObject(cloneObj);
          this.props.copyElem(selectedObject);
          break;
        case 'front':
          selectedObject.bringToFront();
          break;
        case 'back':
          //console.log("selectedObject ",selectedObject);
          selectedObject.sendToBack();
          this.props.canvas.getObjects()[1].sendToBack();
          break;
        case 'align':
          this.props.actiontype('align');
          break;
        /*case 'trash':
          //let clipPath = this.props.canvas.clipPath;
          this.props.canvas.getObjects().map((obj, index) => (
            (index > 0) ? this.props.canvas.remove(obj) : ""
          ));
          //clipPath.set('fill', '#ffffff');
          //clipPath.set('stroke', '#ffffff');
          break;*/
        case 'flip-X':  
          this.setState({flipX : !this.state.flipX});
          selectedObject.set('flipX', this.state.flipX);
          break;
        case 'flip-Y':  
          this.setState({flipY : !this.state.flipY});
          selectedObject.set('flipY', this.state.flipY);
          break;
      } 
      this.props.canvas.renderAll();
      /*if(action === 'delete'){
        this.props.canvas.remove(selectedObject);
      }else if(action === 'group' && selectedObject._objects){
        selectedObject.toGroup();
        this.props.actiontype('group');
      }else if(action === 'ungroup'){
        //let activeObject = this.props.canvas.getActiveObject();
        if(selectedObject.type === "group"){
            let items = selectedObject._objects;
            selectedObject._restoreObjectsState();
            this.props.canvas.remove(selectedObject);
            
            items.map((obj, index) => (
              this.props.canvas.add(obj) , this.props.canvas.item(this.props.canvas.size()-1).hasControls = true
            ));
            this.props.actiontype('ungroup');
        }
      }else if(action === 'copy'){
        let cloneObj = fabric.util.object.clone(selectedObject);
        cloneObj.set("top", cloneObj.top + 30);
        cloneObj.set("left", cloneObj.left + 30);
        this.props.canvas.discardActiveObject();
        this.props.canvas.add(cloneObj);
        this.props.canvas.setActiveObject(cloneObj);
      }else if(action === 'front'){
        selectedObject.bringToFront();
        this.props.canvas.discardActiveObject();
      }else if(action === 'back'){
        selectedObject.sendToBack();
        this.props.canvas.discardActiveObject();
        this.props.canvas.getObjects()[1].sendToBack();
      }else if(action === 'align'){
        this.props.actiontype('align');
      }
      else if(action === 'trash'){
        let clipPath = this.props.canvas.clipPath;
        this.props.canvas.getObjects().map((obj, index) => (
          (index > 0) ? this.props.canvas.remove(obj) : ""
        ));
        clipPath.set('fill', '#ffffff');
      }
      this.props.canvas.renderAll();*/
    }
  }

  render() {
    return (
      <button disabled={this.props.disabled} value={this.props.name} className="btn btn-outline-primary mb-2 px-0" onClick = {this.onToolButtonClick}>
        <i className={this.props.className}></i>
        <span className="label d-block text-uppercase font-weight-bold">{this.props.name}</span>
      </button>
    );
  }
}
export default ToolButton;



