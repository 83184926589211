import React from "react";
import AlignButton from './Assets/AlignButton';
import './AlignPanel.css';

class AlignPanel extends React.Component {
  
    render() {
        return (
            <div className="align-buttonbox border rounded p-2 d-flex justify-content-center mb-3 mb-md-0">
                <AlignButton className="flaticon-align-left" name="left" tooltip="Align Left" canvas={this.props.canvas}></AlignButton>
                <AlignButton className="flaticon-vertical-align-center" name="center" tooltip="Align Center" canvas={this.props.canvas}></AlignButton>
                <AlignButton className="flaticon-align-right" name="right" tooltip="Align Right" canvas={this.props.canvas}></AlignButton>
                <AlignButton className="flaticon-align-top" name="top" tooltip="Align Top" canvas={this.props.canvas}></AlignButton>
                <AlignButton className="flaticon-horizontal-align-center" name="middle" tooltip="Align Middle" canvas={this.props.canvas}></AlignButton>
                <AlignButton className="flaticon-align-bottom" name="bottom" tooltip="Align Bottom" canvas={this.props.canvas}></AlignButton>
            </div>
        );
    }
}
export default AlignPanel;



