import React from "react";

const fabric = window.fabric;

class CustomizeButton extends React.Component {
  constructor(props){
    super(props);    
  }
  
  render() {
    return (
        <button value={this.props.name} className="btn btn-primary text-capitalize mb-3 col text-left rounded-sm" onClick={this.props.onClick}><i className={this.props.icon + " mr-2"}></i>{this.props.name}</button>
    );
  }
}
export default CustomizeButton;



