import React from 'react';
import ReactDOMServer from "react-dom/server";
import Margin from './Margin';

import CustomSvg from '../../ShapeSvgs/Custom';
import RectSvg from '../../ShapeSvgs/Rect';

import Loader from '../Assets/Loader';

const fabric = window.fabric

class Margins extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            overlayImg: null
        };
    }
    
    componentDidMount() {
        this.setCanvasClipping();
        let str = ReactDOMServer.renderToString(<Margin sizeData={this.props.sizeData} actualradious={this.props.actualradious} selectedShape={this.props.selectedShape} ratio_to_fit={this.props.ratio_to_fit} height={this.props.height} width={this.props.width} margin={this.props.margin}></Margin>);
        //console.log("str :: "+str);
        fetch('http://labeldemo.designnbuy.in/addborder/image_convert.php', {
            method: 'post',
            body: str
        })
        .then(res => res.json())
        .then(
            (result) => {
                console.log("result :: ",result);
                this.setState({
                    isLoaded: true,
                    overlayImg: result.url
                });
                //this.props.canvas.overlayImage = null;
                this.props.canvas.setOverlayImage(null,this.props.canvas.renderAll.bind(this.props.canvas));
                this.props.canvas.setOverlayImage(result.url, this.props.canvas.renderAll.bind(this.props.canvas));
            },
            (error) => {
                console.log("error :: "+error);
                this.setState({
                    isLoaded: true,
                    error
                });
            }
        )
        
    }

    setCanvasClipping = ()=>{
        let str;
        if(this.props.selectedShape.shape_title === 'Rect'){
            str = ReactDOMServer.renderToString(<RectSvg x={0} y={0} width={this.props.width + this.props.margin} height={this.props.height + this.props.margin} fill="#ffffff" strokeWidth={1} stroke="#ffffff" rx={this.props.actualradious} ry={this.props.actualradious} margin={this.props.margin}/>);
        }else if(this.props.selectedShape.shape_title === 'Square'){
            str = ReactDOMServer.renderToString(<RectSvg x={0} y={0} width={this.props.width + this.props.margin} height={this.props.height + this.props.margin} fill="#ffffff" strokeWidth={1} stroke="#ffffff" rx={this.props.actualradious} ry={this.props.actualradious} margin={this.props.margin}/>);
        }else if(this.props.selectedShape.shape_title === 'Custom'){
            //str = ReactDOMServer.renderToString(<CustomSvg strokeLinejoin="round" d="M344.67,279.01c64.298,8.722,90.041-34.134,120.24-11.562c19.646,16.802-29.829,12.818,10.961,58.855	c36.631,41.34-54.376-8.105-114.566,16.071c-34.214,13.744-79.149,9.644-39.501-22.769	C346.096,299.751,280.371,270.287,344.67,279.01z" fill="#ffffff" stroke="#ffffff" strokeWidth={this.props.margin} height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>); 
            console.log(this.props.sizeData);
            let d = (this.props.sizeData && this.props.sizeData.customD) ? this.props.sizeData.customD : "M344.67,279.01c64.298,8.722,90.041-34.134,120.24-11.562c19.646,16.802-29.829,12.818,10.961,58.855	c36.631,41.34-54.376-8.105-114.566,16.071c-34.214,13.744-79.149,9.644-39.501-22.769	C346.096,299.751,280.371,270.287,344.67,279.01z" 
            
            str = ReactDOMServer.renderToString(<CustomSvg strokeLinejoin="round" d={d} fill="#ffffff" stroke="#ffffff" strokeWidth={this.props.margin} height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>); 
        }else if(this.props.selectedShape.shape_title === "Diamond"){
            str = ReactDOMServer.renderToString(<CustomSvg strokeLinejoin="round" d="M696,410.5l87.579,43.77L696,498.04l-87.58-43.771L696,410.5z" fill="#ffffff" stroke="#ffffff" strokeWidth={this.props.margin} height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>); 
        }else if(this.props.selectedShape.shape_title === "Oval"){
            str = ReactDOMServer.renderToString(<CustomSvg strokeLinejoin="round" d="M392.006,258.5c48.325,0,87.5,19.477,87.499,43.501S440.329,345.5,392.004,345.5s-87.5-19.476-87.499-43.5  c0-24.024,39.175-43.499,87.499-43.5H392.006z" fill="#ffffff" stroke="#ffffff" strokeWidth={this.props.margin} height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>); 
        }else if(this.props.selectedShape.shape_title === "Circle"){
            str = ReactDOMServer.renderToString(<CustomSvg strokeLinejoin="round" d="M652.006,518.029c24.284,0.001,43.97,19.688,43.97,43.972s-19.687,43.97-43.972,43.97  c-24.283,0-43.97-19.688-43.969-43.972c0-24.283,19.686-43.969,43.969-43.97H652.006z" fill="#ffffff" stroke="#ffffff" strokeWidth={this.props.margin} height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></CustomSvg>); 
        }
        
        //str = ReactDOMServer.renderToString(<Heart d="M70.33952,35.80746C99.34181,-52.10137 212.96642,35.80746 70.33952,148.8372C-72.28889,35.80746 41.33672,-52.10086 70.33952,35.80746z" fill="#ffffff" stroke="#ffffff" strokeWidth={this.props.margin} height={this.props.height} width={this.props.width} margin={this.props.margin} ratio_to_fit={this.props.ratio_to_fit}></Heart>); 
       
      
        let loadedObject;
        fabric.loadSVGFromString(str, function (objects, options) {
            loadedObject = fabric.util.groupSVGElements(objects, options);
            loadedObject.set({
                selectable: false
            });
        });
        this.props.canvas.add(loadedObject);
        loadedObject.center().setCoords();
        //this.props.canvas.centerObject(loadedObject);
        this.props.canvas.clipPath = loadedObject;
        this.props.canvas.renderAll();
    }

    render() {
        const { error, isLoaded, overlayImg } = this.state;
        if (error) {
          return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
          return <Loader></Loader>
        } else {
          return null
        }
      }
}

export default Margins

/*let bleedMargin = 0;
        let cutMargin = this.props.margin;
        let safeMargin = this.props.margin * 2;
        
        let str = ReactDOMServer.renderToString(<Margin x={this.props.x} y={this.props.y} width={this.props.width} height={this.props.height} rx={this.props.rx} ry={this.props.ry} bleedMargin={bleedMargin} cutMargin={cutMargin} safeMargin={safeMargin}></Margin>);
        console.log("str", str);
        let loadedObject;
        
        fabric.loadSVGFromString(str, function (objects, options) {
        loadedObject = fabric.util.groupSVGElements(objects, options);
            loadedObject.set({
                selectable: false
            });
        });

        this.props.canvas.setOverlayImage(loadedObject);
        loadedObject.center().setCoords();*/
        
