import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import '../Material/MaterialItem.css';

//var materialItemData;
//var materialValues;

class MaterialItem extends React.Component {
    renderDiscriptionVal = (discriptionVal)=>{
      if(discriptionVal){
        discriptionVal = discriptionVal.split(",");
      }
      return(
        discriptionVal.map((obj,index) => (
            <li key={index}>{obj}</li>
        ))    
      ) 
    }

    renderMaterialButton = (materialValues) => {
      //console.log("selected :: ",this.props.selectedMaterial);
      //console.log("materialVal :: ",materialValues);
      //console.log("this.props.materialName :: ",this.props.materialName);
    
      return(
        materialValues.map((materialVal,i) => (
          <div className="price-block onsheet-price d-inline-block mr-3 mb-2" key={i} onClick={this.props.onClick}>
            <div className="material-price mb-2 font-weight-bold">{materialVal.price}</div>
            <div className={
              ((this.props.selectedMaterial !== null) && (this.props.selectedMaterial.getAttribute("materialname") === this.props.materialName) && (this.props.selectedMaterial.getAttribute("value") === materialVal.sheet)) ? "material-btn active" : "material-btn"
            
            }
            materialname={this.props.materialName} value={materialVal.sheet}><a href=" " className="btn btn-secondary">{this.props.sheetData[materialVal.sheet].title}</a></div>
          </div>
        ))
      )
    }

    onVideoMouseHover = (e) => {
      e.currentTarget.autoplay = true;
      e.currentTarget.load();
    }

    onVideoMouseLeave = (e) => {
      e.currentTarget.autoplay = false;
      e.currentTarget.load();
    }

    render() {
        return(
          <div className="media material-box bg-white mb-4 d-flex d-lg-flex d-md-block flex-wrap flex-sm-nowrap flex-md-wrap flex-lg-nowrap w-100">
            <video className="col-12 col-sm-6 col-md-12 col-lg-5 px-0 pl-sm-3 px-md-0 pl-lg-3 pr-lg-0 order-sm-2 order-md-1 order-lg-2 mb-4 mb-sm-0 mb-md-4 mb-lg-0" width="100%" height="240" onMouseEnter={this.onVideoMouseHover} onMouseLeave={this.onVideoMouseLeave} poster={this.props.materialMasterData.media}>
              <source src={this.props.materialMasterData.videoSrc} type="video/mp4"></source>
            </video>
            <div className="media-body order-sm-1 order-md-2 order-lg-1">
              <h5 className="mt-0 mb-3">{this.props.materialMasterData.title}</h5>
              <ul>
              {this.renderDiscriptionVal(this.props.materialMasterData.discription)}
              </ul>
              {this.renderMaterialButton(this.props.materialValues)}
            </div>
          </div>
        )
    }
}
export default MaterialItem;


  

