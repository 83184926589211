import React from "react";
import { Raphael } from 'react-raphael';

const CustomSvg = (props) => {
    let bbox = Raphael.pathBBox(props.d);
    let margin = props.margin * props.ratio_to_fit;
    let update_d = String(Raphael.transformPath(props.d, "s " + (props.width - margin)/bbox.width + " " + (props.height - margin)/bbox.height));
    let update_bbox = Raphael.pathBBox(update_d);
    let offset_to_cover = 0;
    
    return(
        <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" width={update_bbox.width + offset_to_cover + margin} height={update_bbox.height + offset_to_cover + margin} viewBox={(update_bbox.x - offset_to_cover/2 - margin/2) + " " + (update_bbox.y - offset_to_cover/2 - margin/2) + " " + (update_bbox.width + offset_to_cover + margin) + " " + (update_bbox.height + offset_to_cover + margin)} distancevalue={props.margin}>
            <path strokeDasharray={props.strokeDasharray} strokeLinejoin={props.strokeLinejoin} id={props.id} d={update_d + "z"} fill={props.fill} strokeWidth={props.strokeWidth} stroke={props.stroke}></path>
        </svg>
    )
}

export default CustomSvg;
