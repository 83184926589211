import React from 'react';
import './CloseButton.css';

class CloseButton extends React.Component {
    render() {
        return (
            <button type="button" className="position-absolute close" aria-label="Close" onClick={this.props.onClick}>
                <i className="fa fa-times-circle"></i>
            </button>
        )
    }
}
export default CloseButton


