import React from "react";
import 'fabric-webpack';
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import FirstStep from '../Shapes/Shapes';
import SecondStep from '../Sizes/Sizes';
import ThirdStep from '../Material/Material';
import FourStep from '../Extras/Extras';
import FiveStep from '../Summary/Summary';
import SixStep from '../DesignTool/DesignCanvas';
import '../DnbWizard/DnbWizard.css';

/*let sizeData = {
  "widthval": 40,
  "heightval": 30,
  "radiousval": 20,
  "quantity": "10",
  "strokeval": 4,
  "strokefill": "#ff0000",
  "fill": "#ffffff",
  "selectedsize": "40x30"
}
let selectedShape = {
  "shape_title": "Rect",
  "shape_id": "1",
  "title": "Rect",
  "sizes": [
    {
      "title": "40x20"
    },
    {
      "title": "40x30"
    },
    {
      "title": "50x20"
    },
    {
      "title": "60x30"
    },
    {
      "title": "60x40"
    }
  ],
  "default_size": "40x30",
  "radious": "2"
}
let getJsonData = {
  "config": {
    "unit": "mm",
    "margin": 5,
    "font": [
      "After Shok",
      "Aldo",
      "Aleo",
      "Alex Brush"
    ]
  },
  "tooldata": {
    "default_title": "Shapes",
    "optionvalues": [
      [
        {
          "shape_title": "Rect",
          "shape_id": "1",
          "title": "Rect",
          "sizes": [
            {
              "title": "40x20"
            },
            {
              "title": "40x30"
            },
            {
              "title": "50x20"
            },
            {
              "title": "60x30"
            },
            {
              "title": "60x40"
            }
          ],
          "default_size": "40x30",
          "radious": "2"
        },
        {
          "shape_title": "Square",
          "shape_id": "2",
          "title": "Rect",
          "sizes": [
            {
              "title": "20x20"
            },
            {
              "title": "30x30"
            },
            {
              "title": "40x40"
            },
            {
              "title": "50x50"
            },
            {
              "title": "60x60"
            }
          ],
          "default_size": "40x40",
          "radious": "2"
        },
        {
          "shape_title": "Oval",
          "shape_id": "3",
          "title": "Oval",
          "sizes": [
            {
              "title": "40x20"
            },
            {
              "title": "40x30"
            },
            {
              "title": "50x20"
            },
            {
              "title": "60x30"
            },
            {
              "title": "60x40"
            }
          ],
          "default_size": "40x30"
        },
        {
          "shape_title": "Diamond",
          "shape_id": "4",
          "title": "Diamond",
          "sizes": [
            {
              "title": "20x20"
            },
            {
              "title": "30x30"
            },
            {
              "title": "40x20"
            },
            {
              "title": "50x20"
            },
            {
              "title": "40x40"
            }
          ],
          "default_size": "40x20"
        },
        {
          "shape_title": "Circle",
          "shape_id": "5",
          "title": "Circle",
          "sizes": [
            {
              "title": "20x20"
            },
            {
              "title": "30x30"
            },
            {
              "title": "40x40"
            },
            {
              "title": "50x50"
            },
            {
              "title": "60x60"
            }
          ],
          "default_size": "30x30"
        },
        {
          "shape_title": "Custom",
          "shape_id": "6",
          "title": "Custom",
          "sizes": [
            {
              "title": "40x20"
            },
            {
              "title": "40x30"
            },
            {
              "title": "50x20"
            },
            {
              "title": "60x30"
            },
            {
              "title": "60x40"
            }
          ],
          "default_size": "40x30"
        }
      ]
    ]
  },
  "material": [
    {
      "id": "1",
      "material": "M1",
      "values": [
        {
          "sheet": "S1",
          "price": "$10.00",
          "extra_option": [
            {
              "title": "Gloss lamination",
              "discription": "Standard (white),Glossy / shiny,Waterproof,Weatherproof,Tear-resistant,Permanent adhesive",
              "media": "http://localhost:3000/material.jpg",
              "values": [
                {
                  "title": "Gloss lamination",
                  "price": "$10.00",
                  "default": "false"
                },
                {
                  "title": "No lamination",
                  "price": "$20.00",
                  "default": "true"
                }
              ]
            },
            {
              "title": "White ink (solid colours)",
              "discription": "White ink will be printed under the colour in your design (left picture),Gives the ink a solid appearance,If not selected, colours will be semi-transparent (right picture)",
              "media": "http://localhost:3000/material.jpg",
              "values": [
                {
                  "title": "White ink on",
                  "price": "$10.00",
                  "default": "false"
                },
                {
                  "title": "white ink off",
                  "price": "$20.00",
                  "default": "true"
                }
              ]
            }
          ]
        },
        {
          "sheet": "R1",
          "price": "$100.00",
          "extra_option": [
            {
              "title": "Gloss lamination",
              "discription": "Standard (white),Glossy / shiny,Waterproof,Weatherproof,Tear-resistant,Permanent adhesive",
              "media": "http://localhost:3000/material.jpg",
              "values": [
                {
                  "title": "Gloss lamination",
                  "price": "$10.00",
                  "default": "true"
                },
                {
                  "title": "No lamination",
                  "price": "$20.00",
                  "default": "false"
                }
              ]
            }
          ]
        }
      ]
    },
    {
      "id": "2",
      "material": "M2",
      "values": [
        {
          "sheet": "S1",
          "price": "$20.00"
        },
        {
          "sheet": "R1",
          "price": "$200.00"
        }
      ]
    },
    {
      "id": "3",
      "material": "M4",
      "values": [
        {
          "sheet": "S1",
          "price": "$30.00"
        }
      ]
    },
    {
      "id": "4",
      "material": "M5",
      "values": [
        {
          "sheet": "R2",
          "price": "$40.00",
          "extra_option": [
            {
              "title": "Gloss lamination",
              "discription": "Standard (white),Glossy / shiny,Waterproof,Weatherproof,Tear-resistant,Permanent adhesive",
              "media": "http://localhost:3000/material.jpg",
              "values": [
                {
                  "title": "Gloss lamination",
                  "price": "$10.00",
                  "default": "true"
                },
                {
                  "title": "No lamination",
                  "price": "$20.00",
                  "default": "false"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "qty": [
    1,
    2,
    3,
    4,
    5,
    6,
    10,
    100
  ],
  "matrial_master": {
    "M1": {
      "title": "Gloss white vinyl / polyester",
      "discription": "Standard (white),Glossy / shiny,Waterproof,Weatherproof,Tear-resistant,Permanent adhesive",
      "media": "http://localhost:3000/material.jpg",
      "videoSrc": "http://localhost:3000/gloss-white-vinyl-polyester.mp4"
    },
    "M2": {
      "title": "Matt white vinyl / polyester",
      "discription": "Standard (white),Matt / silky,Waterproof,Weatherproof,Tear-resistant,Permanent adhesive",
      "media": "http://localhost:3000/material.jpg",
      "videoSrc": "http://localhost:3000/gloss-white-vinyl-polyester.mp4"
    },
    "M3": {
      "title": "Gloss white vinyl / polyester",
      "discription": "Metallic,Glossy / shiny,Waterproof,Weatherproof,Tear-resistant,Permanent adhesive",
      "media": "http://localhost:3000/material.jpg",
      "videoSrc": "http://localhost:3000/gloss-white-vinyl-polyester.mp4"
    },
    "M4": {
      "title": "Matt white vinyl / polyester",
      "discription": "Metallic,Glossy / shiny,Waterproof,Weatherproof,Tear-resistant,Permanent adhesive",
      "media": "http://localhost:3000/material.jpg",
      "videoSrc": "http://localhost:3000/gloss-white-vinyl-polyester.mp4"
    },
    "M5": {
      "title": "Gloss white vinyl / polyester",
      "discription": "Metallic,Glossy / shiny,Waterproof,Weatherproof,Tear-resistant,Permanent adhesive",
      "media": "http://localhost:3000/material.jpg",
      "videoSrc": "http://localhost:3000/gloss-white-vinyl-polyester.mp4"
    }
  },
  "sheet_rool_master": {
    "S1": {
      "title": "On sheet",
      "size": "10x20"
    },
    "S2": {
      "title": "On sheet",
      "size": "5x20"
    },
    "S3": {
      "title": "On sheet",
      "size": "8x20"
    },
    "S4": {
      "title": "On sheet",
      "size": "1x2"
    },
    "S5": {
      "title": "On sheet",
      "size": "2x1"
    },
    "R1": {
      "title": "On rool",
      "size": "10x20"
    },
    "R2": {
      "title": "On rool",
      "size": "5x20"
    },
    "R3": {
      "title": "On rool",
      "size": "8x20"
    },
    "R4": {
      "title": "On rool",
      "size": "1x2"
    },
    "R5": {
      "title": "On rool",
      "size": "2x1"
    }
  }
};*/

class DnbWizard extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        currentStep : 0,
        isFinish : false,
        selectedShape : null,

        sizeData : null,
        materialData : null,
        extraData : null,
        customizeData : null,

        selectedQty : "",
        selectedMaterial : null,
      }
    }

    
    onPreviousClick = () =>{
      if("onPreviousClick" in this.refs.child){
        if(this.refs.child.onPreviousClick()){
          if(this.state.currentStep === 1){
            this.setState({sizeData : this.refs.child.onPreviousClick()});
          }else if(this.state.currentStep === 2){
            this.setState({materialData : this.refs.child.onPreviousClick()});
          }else if(this.state.currentStep === 3){
            this.setState({extraData : this.refs.child.onPreviousClick()});
          }else if(this.state.currentStep === 4){
            this.setState({customizeData : this.refs.child.onPreviousClick()});
          }
        }
      }
     if(this.state.currentStep > 0){
        this.setState({currentStep : this.state.currentStep-1});
      }
    }
    
    onNextClick = () =>{

      //console.log("this.state.currentStep :: "+this.state.currentStep);
      //console.log("this.state.materialData :: ",this.state.materialData);
      
      if("onNextClick" in this.refs.child){
        //console.log("this.refs.child :: ",this.refs.child);
        if(this.refs.child.onNextClick()){
          if(this.state.currentStep === 1){
            this.setState({sizeData : this.refs.child.onNextClick()});
          }else if(this.state.currentStep === 2){
            this.setState({materialData : this.refs.child.onNextClick()});
          }else if(this.state.currentStep === 3){
            this.setState({extraData : this.refs.child.onNextClick()});
          }else if(this.state.currentStep === 4){
            this.setState({customizeData : this.refs.child.onNextClick()});
          }
        }
      }

      if("onValidate" in this.refs.child){
        if(this.refs.child.onValidate()){
          if(this.state.currentStep < this.props.tabs.length - 1){
            this.setState({currentStep : this.state.currentStep+1});
          }
        }
      }else{
        if(this.state.currentStep < this.props.tabs.length - 1){
          this.setState({currentStep : this.state.currentStep+1});
        }
      }
    }

    onFinishClick = () => {
      this.setState({isFinish:true});
    }

    onTabClick = (e) => {
      if(e.currentTarget.getAttribute('indexnumber') < this.state.currentStep){
        this.setState({currentStep : parseInt(e.currentTarget.getAttribute('indexnumber'))});
      }
    }

    onShapeClick = (e) => {
      this.setState({sizeData : null});
      this.setState({selectedShape : e});
      this.setState({selectedQty : ""});
    }

    onQtySelect = (qty) => {
      this.setState({selectedQty:qty});
    }

    onMaterialSelect = (material) =>{
      this.setState({selectedMaterial:material});
      this.setState({extraData:null});
    }
     
    createTabs = ()=> {
      //console.log("customizeData :: ",this.state.customizeData);
      //console.log("extra Data :: ",this.state.extraData);
      //console.log("getJsonData :: ",this.props.getJsonData);
      //console.log("this.state.sizeData :: ",this.state.sizeData);
      //console.log("this.state.selectedShape ::",this.state.selectedShape);
      if(this.state.currentStep === 0){
        return <div><FirstStep ref="child" key={this.props.tabs.stepName} getJsonData={this.props.getJsonData} onClick={this.onShapeClick} selectedShape={this.state.selectedShape}/></div>
      }else if(this.state.currentStep === 1){
        return <div><SecondStep ref="child" key={this.props.tabs.stepName} getJsonData={this.props.getJsonData} selectedShape={this.state.selectedShape} sizeData={this.state.sizeData} onQtySelect={this.onQtySelect}/></div>
      }else if(this.state.currentStep === 2){
        return <div><ThirdStep ref="child" key={this.props.tabs.stepName} getJsonData={this.props.getJsonData} materialData={this.state.materialData} onMaterialSelect={this.onMaterialSelect}/></div>
      }else if(this.state.currentStep === 3){
        return <div><FourStep ref="child" key={this.props.tabs.stepName} getJsonData={this.props.getJsonData} materialData={this.state.materialData} extraData={this.state.extraData}/></div>
      }else if(this.state.currentStep === 4){
        return <div><SixStep ref="child" getJsonData={this.props.getJsonData} sizeData={this.state.sizeData} selectedShape={this.state.selectedShape} /*isFinish={this.state.isFinish}*//*getJsonData={getJsonData} sizeData={sizeData} selectedShape={selectedShape}*//></div>
      }else if(this.state.currentStep === 5){
        return <div><FiveStep ref="child" key={this.props.tabs.stepName} getJsonData={this.props.getJsonData} selectedShape={this.state.selectedShape} sizeData={this.state.sizeData} materialData={this.state.materialData} extraData={this.state.extraData} isFinish={this.state.isFinish} customizeData={this.state.customizeData} /></div>
      }
    }
    
    render() {
      //console.log("this.state.selectedQty :: ",this.state.selectedQty);
      let nextBtndisabled;
      if(this.state.currentStep === 0){
        nextBtndisabled = this.state.selectedShape === null ? "disabled" : "";
      }else if(this.state.currentStep === 1){
        nextBtndisabled = (this.state.selectedQty === "" || this.state.selectedQty == 0) ? "disabled" : "";
      }else if(this.state.currentStep === 2){
        nextBtndisabled = this.state.selectedMaterial === null ? "disabled" : "";
      }
      

      var prevBtnStyle =  this.state.currentStep === 0 ? {display : "none"} : {display : "block"};
      var nextBtnStyle =  this.state.currentStep === this.props.tabs.length - 1 ? {display : "none"} : {display : "block"};
      var finishBtnStyle =  this.state.currentStep === this.props.tabs.length - 1 ? {display : "block"} : {display : "none"};

      return(
        
         <div className="container">
           <div className="row">
            <div className="mr-auto ml-auto col-12 col-md-12 my-3">
               <div className="stickers-container">
                 <div className="tabs-header text-center mb-4">
                    <ul className="nav">
                      {
                        this.props.tabs.map((tabsobj,index) => (
                          <li indexnumber={index} onClick={this.onTabClick} className="nav-item" key={index}><span className={
                            (this.state.currentStep === index ? "active " : "")
                          }><span className="nav-link"><i className={tabsobj.stepIcon}></i> {tabsobj.stepName}</span></span></li>
                        ))
                      }
                      
                    </ul>
                  </div>
                  <div className="tabs-content position-relative">
                    {this.createTabs()}
                  </div>
                  <div className="tabs-footer">
                    <div className="float-left">
                      <button style={prevBtnStyle} className="btn-previous btn btn-secondary" onClick={this.onPreviousClick}>Previous</button>
                    </div>
                    <div className="float-right">
                      <button style={nextBtnStyle} disabled={nextBtndisabled} className="btn-next btn btn-secondary" onClick={this.onNextClick}>Next</button>
                    </div>
                    <div className="float-right">
                      <button style={finishBtnStyle} className="btn-finish btn btn-secondary" onClick={this.onFinishClick}>Finish</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
         </div>
      ) 
    }
  }
export default DnbWizard;

/*<div className="float-right">
  <button style={finishBtnStyle} className="btn-finish btn btn-secondary" onClick={this.onFinishClick}>Finish</button>
</div>*/



