import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import '../Material/Material.css';
import MaterialItems from '../Material/MaterialItems';


class ThirdStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedMaterial : null
    };
  }

  componentWillMount = () => {
    if(this.props.materialData !== null){      
        this.setState({selectedMaterial : this.props.materialData.selectedMaterial});
    }
  }

  onValidate() {
    return this.state.selectedMaterial === null ? false : true;
  }

  onNextClick = () => {
    return this.state;
  }

  onPreviousClick = () => {
    return this.state;
  }

  onMaterialClick = (e) => {
    this.setState({selectedMaterial : e.target});
    this.props.onMaterialSelect(e.target);;
  }

  rendermaterials = () => {
    return(
      this.props.getJsonData.material.map((data,index) => (
        <MaterialItems key={index} materialMasterData={this.props.getJsonData.matrial_master} sheetData={this.props.getJsonData.sheet_rool_master} materialData={data} onClick={this.onMaterialClick} selectedMaterial={this.state.selectedMaterial}/>
      )) 
    )
  }
  
  render() {
    return(
      <div className="tabs-content-wrapper">
          <div className="tab-title col-8 col-md-12 mx-auto p-0 text-center mb-4">
              <h2 className="position-relative text-center d-inline-block text-uppercase m-0">choose your material</h2>
          </div>
          <div className="tabs-inner-content">
            <div className="material-choose-wrapper text-left">
              <div className="row" >
                {this.rendermaterials()}
              </div>
            </div>
          </div>
        </div>
      ) 
    }
  }
export default ThirdStep;



