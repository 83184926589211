import React from "react";

const RectSvg = (props) => {
  //console.log("margin :: "+props.margin);
  let offset = 2;
  return(
    <svg id='Layer_1' xmlns='http://www.w3.org/2000/svg' width={props.width} height={props.height} distancevalue={props.margin} shapetype="regular">
      <rect id={props.id} strokeDasharray={props.strokeDashArray} x={props.x + props.margin/2 + offset/2} y={props.y + props.margin/2 + offset/2} width={props.width  - props.margin - offset} height={props.height - props.margin - offset} fill={props.fill} rx={props.rx} ry={props.ry} strokeWidth={props.strokeWidth} stroke={props.stroke}></rect>
    </svg>
  )
};

export default RectSvg;