import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
//import '../Shapes/ShapeItems.css';
import ShapeItem from './ShapeItem';

var jsonData;

class ShapeItems extends React.Component {
    render() {
        jsonData = this.props.optionsData;    

        //console.log("this.props.onClick :: "+this.props.onClick);

        return (
            <div className="row" >
                {
                    jsonData.map(optionsData => (
                        <ShapeItem selectedShape={this.props.selectedShape} /*changeSelected={this.props.changeSelected} selected={this.props.selected}*/ onClick={this.props.onClick} optionsData={optionsData} key={optionsData.shape_id}></ShapeItem>
                    ))    
                }
            </div>
        )
    }
}
export default ShapeItems;

