import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import '../Shapes/ShapeItems.css';

import RectSvg  from '../ShapeSvgs/Rect';
import SquareSvg  from '../ShapeSvgs/Square';
import CircleSvg from '../ShapeSvgs/Circle';
import OvalSvg from '../ShapeSvgs/Oval';
import DiamondSvg from "../ShapeSvgs/Diamond";
import CustomSvg from "../ShapeSvgs/Custom";

var shapeItemData;

class ShapeItem extends React.Component {
    
    changeSelected = (obj)=> {
        //console.log("this.props.optionsData :: ",this.props.optionsData);
        //this.props.changeSelected(this.props.optionsData.shape_id);
        this.props.onClick(this.props.optionsData);
    }

    drawShape = (shapeItemData) => {
        //console.log("shapeItemData.shape_title :: ",shapeItemData.shape_title);
        if(shapeItemData.shape_title === "Rect"){
            return <div><RectSvg x={0} y={0} margin={0} width={175.5} height={87.54} fill="#c8c8c8" rx="5" ry="5"/></div> 
        }else if(shapeItemData.shape_title === "Square"){
            return <div><RectSvg x={0} y={0} margin={0} width={87.54} height={87.54} fill="#c8c8c8" rx="5" ry="5"/></div> 
        }else if(shapeItemData.shape_title === "Circle"){
            return <div><CustomSvg margin={0} ratio_to_fit={0}/*width={200} height={100} viewBox='306.301 260.799 178 89'*/ fill="#c8c8c8" d='M652.006,518.029c24.284,0.001,43.97,19.688,43.97,43.972s-19.687,43.97-43.972,43.97  c-24.283,0-43.97-19.688-43.969-43.972c0-24.283,19.686-43.969,43.969-43.97H652.006z' /></div> 
        }else if(shapeItemData.shape_title === "Oval"){
            return <div><CustomSvg margin={0} ratio_to_fit={0}/*width={200} height={100} viewBox='306.301 260.799 178 89'*/ fill='#c8c8c8' d='M392.006,258.5c48.325,0,87.5,19.477,87.499,43.501S440.329,345.5,392.004,345.5s-87.5-19.476-87.499-43.5  c0-24.024,39.175-43.499,87.499-43.5H392.006z'/></div>
        }else if(shapeItemData.shape_title === "Diamond"){
            return <div><CustomSvg margin={0} ratio_to_fit={0}/*width="175.159" height="87.54" /*viewBox="608.42 410.5 175.159 87.54"*/ fill='#c8c8c8' d='M696,410.5l87.579,43.77L696,498.04l-87.58-43.771L696,410.5z'/></div>
        }else if(shapeItemData.shape_title === "Custom"){
            return <div><CustomSvg margin={0} ratio_to_fit={0} /*width={178} height={89} /*viewBox='306.301 260.799 178 89'*/ fill='#c8c8c8' d='M344.67,279.01c64.298,8.722,90.041-34.134,120.24-11.562c19.646,16.802-29.829,12.818,10.961,58.855	c36.631,41.34-54.376-8.105-114.566,16.071c-34.214,13.744-79.149,9.644-39.501-22.769	C346.096,299.751,280.371,270.287,344.67,279.01z'/></div>
        }
    }

    render() {
        shapeItemData = this.props.optionsData;    
        let boxClass = ["box"];
        boxClass.push("col-lg-4 col-md-4 col-sm-6 col-xs-12 shape-grid d-flex align-items-center justify-content-center flex-column my-4 my-md-5 my-sm-4");
        
        if(this.props.selectedShape != null){
            if(this.props.selectedShape.shape_id === this.props.optionsData.shape_id) {
                boxClass.push("active");
            }                
        }

        return(               
            <div onClick={(e)=>{this.changeSelected(this);}} className={boxClass.join(' ')} key={shapeItemData.shape_id}>     
                {this.drawShape(shapeItemData)}
                <div><h6 className="shape-name font-weight-bold">{shapeItemData.shape_title}</h6></div>  
            </div>
        )
    }
}
export default ShapeItem;
//onClick={(e)=>{this.changeSelected(this);}}
//{boxClass.join(' ')}

  

