import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap-grid.min.css";
import ShapeItems from './ShapeItems';



class FirstStep extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        error : null,
        selected : null,
      };
    }

    componentWillMount(){
      //console.log("shape will mount fired***");
    }

    onValidate = () => {
      return this.props.selectedShape === null ? false : true;
    }

    /*changeSelected = (id)=> {
      this.setState({selected:id},()=>{ console.log(this.state); });
    }*/

    rendershapes = () =>{
      return(
        this.props.getJsonData != null ?
        (
          this.props.getJsonData.tooldata.optionvalues.map((optionsData,index) => (
            <ShapeItems onClick={this.props.onClick} key={index} optionsData={optionsData} selectedShape={this.props.selectedShape} /*changeSelected={this.changeSelected}*/ /*selected={this.state.selected}*//>
          ))
        ):null
      )
    }

    //key= optionsData.shape_id
    
    render() {
      return(
        <div className="tabs-content-wrapper">
          <div className="tab-title w-100 text-center mb-4">
              <h2 className="position-relative text-center d-inline-block text-uppercase m-0">choose your shapes</h2>
          </div>
          <div className="tabs-inner-content">
              {this.rendershapes()}
          </div>
        </div>
      ) 
    }
  }
export default FirstStep;



