import React from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash.omit'

const fabric = window.fabric

class Image extends React.Component {
  static propTypes = {
    canvas: PropTypes.object,
    top: PropTypes.number.isRequired,
    left: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    scaleX: PropTypes.number.isRequired,
    scaleY: PropTypes.number.isRequired,
  }

  static defaultProps = {
    top: 0,
    left: 0,
    scaleX: 1,
    scaleY: 1,
    flipX : false,
    flipY : false,
    angle : 0,
    isCopy : false
  }

  componentDidMount() {
    //console.log("this.props.scale :: ",this.props.scale);
    //const options = omit(this.props, ['scale'])
    let imgOriginalPath = this.props.url;
    fabric.util.loadImage(this.props.url, img => {
    //fabric.Image.fromURL(this.props.url, image => {



      let image = new fabric.Image(img,this.props);
      let sx,sy;
      sx = (this.props.canvas.width < image.width) ? this.props.canvas.width / image.width / 2 : image.width / this.props.canvas.width / 2;
      //sy = (this.props.canvas.height < image.height) ? this.props.canvas.height / image.height / 2 : image.height / this.props.canvas.height / 2 ;
      //console.log("sx :: "+sx);
      //console.log("sy :: "+sy);
      if(!this.props.isCopy){
        image.set({
          scaleX: sx,//(this.props.canvas.width / image.width) / 2,
          scaleY: sx,//(this.props.canvas.height / image.height) / 2,
          originalPath : imgOriginalPath
        });
      }else{
        image.set({
          scaleX: this.props.scaleX,
          scaleY: this.props.scaleY,
          originalPath : this.props.originalPath
        });
      }

      image.set({
        transparentCorners: false,
        cornerStyle: 'circle',
        cornerColor: '#009FDD',
        rotationCursor: 'grab'
      })

      image.setControlVisible("mt", false);
      image.setControlVisible("ml", false);
      image.setControlVisible("mr", false);
      image.setControlVisible("mb", false);
      image.set("rotatingPointOffset",30);

      this.props.canvas.add(image);
      if(!this.props.isCopy){
        this.props.canvas.centerObject(image);
      }
      this.props.canvas.discardActiveObject();
      this.props.canvas.setActiveObject(image);
    })
    //}, options)


    /*const options = omit(this.props, ['scale'])
    fabric.Image.fromURL(this.props.url, img => {
      img.set({
        scaleX: (this.props.canvas.width / img.width) / 2,
        scaleY: (this.props.canvas.height / img.height) / 2,
        originX: "center", 
        originY: "center"
      });
    
      img.set({
        transparentCorners: false,
        cornerStyle: 'circle',
        cornerColor: '#009FDD',
        rotationCursor: 'grab'
      })

      img.setControlVisible("mt", false);
      img.setControlVisible("ml", false);
      img.setControlVisible("mr", false);
      img.setControlVisible("mb", false);
      img.set("rotatingPointOffset",30);

      this.props.canvas.add(img);
      this.props.canvas.centerObject(img);
      this.props.canvas.setActiveObject(img);
    }, options)*/

  }

  render() {
    return null
  }
}

export default Image
