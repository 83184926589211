import React from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import './ImageCrop.css';
import CustomizeButton from './Assets/CustomizeButton';

class ImageCrop extends React.Component {
    constructor(props) {
        super(props);
        this.cropImage = this.cropImage.bind(this);
    }

    state = {
        minContainerWidth : 465,
        minContainerHeight : 425
    }

    componentDidMount = () => {
        //window.addEventListener('resize', this.onWindowResize);
        //this.onWindowResize();
    }

    onWindowResize = ()=>{
        //console.log("window.innerWidth :: ",window.innerWidth);
        /*let windowResizeTimeOut;
        clearTimeout(windowResizeTimeOut);
        windowResizeTimeOut = setTimeout(function() {
            if(window.innerWidth > 575){
                this.setState({minContainerWidth : 465});
                this.setState({minContainerHeight : 425});
            }else if(window.innerWidth < 575){
                this.setState({minContainerWidth : 400});
                this.setState({minContainerHeight : 300});
            }
        }.bind(this), 300);*/
        
    }

    /*_crop() {
        const dataUrl = this.refs.cropper.getCroppedCanvas().toDataURL();
        console.log(dataUrl);
    }*/

    cropImage = ()=> {
        if(typeof this.refs.cropper.getCroppedCanvas() === 'undefined') return
        this.props.onApply(this.refs.cropper.getCroppedCanvas().toDataURL());
    }

    cancleCrop = ()=>{
        this.props.onCancel(false);
    }

    render() {
        //console.log("this.props.imgPath :: "+this.props.imgPath);
        let containerWidth, containerHeight;
        if(window.screen.width >= 575){
            containerWidth = 465;
            containerHeight = 425;
        }else if(window.screen.width <= 574 && window.screen.width >= 480){
            containerWidth = 400;
            containerHeight = 300;
        }else{
            containerWidth = 300;
            containerHeight = 230;
        }
        const style = {
            display: this.props.cropWindow ? 'block' : 'none',
            width: '100%',
            height: '100%',
            
        }
        return (
            <div className="modal crop-container-modal" style={style} >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">
                            <Cropper className="img-container"
                                ref='cropper'
                                src = { this.props.imgPath }
                                style={{ width: '100%' }}
                                
                                minContainerWidth={containerWidth}
                                minContainerHeight={containerHeight}

                                //minCropBoxWidth = {400}
                                //minCropBoxHeight = {400}

                                //minCanvasWidth = {400}
                                //minCropBoxWidth = {400}
                                guides={true}
                                //crop={this._crop.bind(this)} 
                            />
                        </div>
                        <div className="modal-footer justify-content-center">
                            <CustomizeButton icon="fa fa-check" name="Apply" onClick={this.cropImage}></CustomizeButton>
                            <CustomizeButton icon="fa fa-close" name="Cancel" onClick={this.cancleCrop}></CustomizeButton>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default ImageCrop;