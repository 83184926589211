import React from "react";
import { MoonLoader } from 'react-spinners';
import { css } from '@emotion/core';
import "../Assets/Loader.css";

const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;

class Loader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loading: true
        }
    }
    render() {
        return (
            <div className='sweet-loading fixed-top fixed-bottom m-auto d-flex justify-content-center align-items-center align-content-center'>
                <MoonLoader
                    //css={override}
                    sizeUnit={"px"}
                    size={100}
                    color={'#007bff'}
                    loading={this.state.loading}
                />
                <span className="d-block text-center position-absolute loader-txt">Please wait...</span>
            </div> 
        )
    }
}
export default Loader
