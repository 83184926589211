import React from "react";

const fabric = window.fabric;

class AlignButton extends React.Component {
  onToolButtonClick = (e) => {
    let action = e.target.value;
    let selectedObject = this.props.canvas.getActiveObject();
    
    //console.log("action :: ",action);
    //console.log("selectedObject :: ",selectedObject);
    /*if(action && selectedObject){
        if(action === 'left'){
            selectedObject.set({
                left: 0
            });
            selectedObject.setCoords();
            this.props.canvas.renderAll();
        }
    }*/
    if(action && selectedObject){
        switch (action) {
            case 'left':
                selectedObject.set({
                    left: 0
                });
                selectedObject.setCoords();
                this.props.canvas.renderAll();
            break;
            case 'right':
                selectedObject.set({
                    left: this.props.canvas.width - (selectedObject.width * selectedObject.scaleX)
                });
                selectedObject.setCoords();
                this.props.canvas.renderAll();
            break;
            case 'top':
                selectedObject.set({
                    top: 0
                });
                selectedObject.setCoords();
                this.props.canvas.renderAll();
            break;
            case 'bottom':
                selectedObject.set({
                    top: this.props.canvas.height - (selectedObject.height * selectedObject.scaleY)
                });
                selectedObject.setCoords();
                this.props.canvas.renderAll();
            break;
            case 'center':
                selectedObject.set({
                    left: (this.props.canvas.width / 2) - ((selectedObject.width * selectedObject.scaleX) / 2)
                });
                selectedObject.setCoords();
                this.props.canvas.renderAll();
            break;
            case 'middle':
                selectedObject.set({
                    top: (this.props.canvas.height / 2) - ((selectedObject.height * selectedObject.scaleY) / 2)
                });
                selectedObject.setCoords();
                this.props.canvas.renderAll();
            break;
        }
    }
  }

  render() {
    return (
        <button disabled={this.props.disabled} value={this.props.name} className="border-0 mx-1 mx-sm-3 mx-md-2 mx-lg-1 px-0 text-primary" data-toggle="tooltip" data-placement="top" title={this.props.tooltip} onClick = {this.onToolButtonClick}>
        <i className={this.props.className}></i>
      </button>
    );
  }
}
export default AlignButton;



