import React from "react";

class Alert extends React.Component {
    
    render() {
        const modalStyle = {
            background: 'rgba(0,0,0,0.5)'
        };
        return (
            <div className="modal d-flex position-absolute align-content-center align-items-center" tabIndex="-1" role="dialog" style={modalStyle}>
                <div className="modal-dialog w-100" role="document">
                    <div className="modal-content">
                    <div className="modal-header py-2">
                        <h5 className="modal-title">Alert</h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.onClick}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body text-center">
                        <p>{this.props.msg}</p>
                        <button type="button" className="btn btn-primary" onClick={this.props.onClick}>OK</button>
                    </div>
                    </div>
                </div>
            </div>
        );   
    }
}
export default Alert;